<footer class="main-footer ptb-80">
    <div class="container">
        <!--
            <div class="footer-social-holder">
                <ul class="footer-social">
                <li>
                    <a href="#"><span class="ion-social-facebook-outline"></span></a>
                </li>
                <li>
                    <a href="https://twitter.com/hellotag_"><span class="ion-social-twitter-outline"></span></a>
                </li>
                <li>
                    <a href="#"><span class="ion-social-linkedin-outline"></span></a>
                </li>
                <li>
                    <a href="https://instagram.com/hellotag_"><span class="ion-social-instagram-outline"></span></a>
                </li>
                <li>
                    <a href="#"><span class="ion-social-snapchat-outline"></span></a>
                </li>
                <li>
                    <a href="#"><span class="ion-laptop"></span></a>
                </li>
                
            </ul>
        </div>
    -->
        <div class="footer-copyright">
            <p>&copy; Copyright HelloTag | All Rights are Reserved</p>
        </div>
    </div><!-- End Container -->
</footer><!-- End Footer -->