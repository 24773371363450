<section class="full-page"
style="height: 50%;background: linear-gradient(180deg, #000 0%, #373b3c 99.99%, rgba(76, 165, 251, 0.00) 100%)">
<div class="top-page">
        <div class="aninimated-title">
            <h2 class="text-uppercase heading-light ls-1 ah-headline">
                <span>Gérer mon compte</span>

                <!-- <span class="ah-words-wrapper">
                        <b class="is-visible">Creative Designer</b>
                        <b>Passionate Coder</b>
                        <b>Digital Dreamer</b>
                    </span> -->
            </h2>
        </div>
    </div><!-- End Container -->
</section><!-- End Section -->
<!-- ==============================================
    **PROFILE**
    =============================================== -->
<section class="bbs1 bc-light pb-60">
    <div class="container">
        <div class="bottom-page" style="border-radius: 20px;">
            <div class="profile-card-two">
                <header class="profile-card-header">
                    <!-- here’s the avatar -->
                    <a href="#">
                        <img src="../../../assets/images/logo2.svg" alt="image">
                    </a>
                    <h2 style="text-align: center;">Connecte toi pour gérer ton compte !</h2>
                    <h4 style="text-align: center;">désactive,supprime ou active findme sur ton compte!</h4>
                </header>
                <div *ngIf="!connected" class="profile-bio" style="margin: auto;display: table;">
                    <form style="display:table;margin:auto">
                        <input type="text" placeholder="Mail" #mail style="margin:auto;display:table;margin-top:10px;padding:2px;text-align: center;">
                        <br>
                        <input type="password" placeholder="Mot de passe" style="margin:auto;display:table;padding:2px;text-align: center;" #mdp>
                        <div *ngIf="loaded2" class="loading2">
                            <div class="loader2"></div>
                        </div>
                        <br>
                        <span style="color: red;margin:auto;display: table;text-align: center;">{{message}}</span>
                    </form>
                    <div style="display: table;margin:auto">
                        <div class="row">
                            <div class="col-md-12">
                                <button (click)="co(mail,mdp)" style="    background: #3dc2ff;
                                color: white;
                                " class="btn btn-info">
                                    Se connecter
                                </button>

                                <button (click)="googleAuth()" style="    background: #3dc2ff;
                                color: white;
                                margin-left: 10px;" class="btn btn-info">
                                    Se connecter avec Google </button>
                            </div>

                        </div>

                        <!-- <button (click)="co(mail,mdp)"
                            style="margin-top: 20px;width: 200;height: 30px; border-radius: 6px;margin-left: 5px;background:#3dc2ff; color: white;">
                            Se connecter
                        </button>
                        <button (click)="googleAuth()"
                            style="margin-top: 20px;width: 200;height: 30px; border-radius: 6px;margin-left: 5px;background:#3dc2ff; color: white;">
                            Se connecter avec Google
                        </button> -->
                    </div>


                </div>

                <div *ngIf="connected" class="profile-bio" style="margin: auto;display: table;">
                    <div style="display: table; margin:auto">
                        <button (click)="suppr()" style="    background: #3dc2ff;
                        color: white;
                        width: 100%;
                        margin-top:10px" class="btn btn-info">
                            Supprimer mon compte définitivement
                        </button>
                        <button (click)="desac()" style="    background: #3dc2ff;
                        color: white;
                        width: 100%;
                        margin-top:10px" class="btn btn-info">
                            Désactiver mon compte
                        </button>
                        <button (click)=" findMe()" style="    background: #3dc2ff;
                        color: white;
                        width: 100%;
                        margin-top:10px" class="btn btn-info">
                            Activer FindMe
                        </button>
                    </div>


                </div>


            </div>
        </div>

    </div><!-- End Container -->
</section>
<button id=" openModalButton" [hidden]="true" data-toggle="modal" data-target="#myModal">Open Modal</button>

<div id="myModal" class="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Compte supprimé</h5>
                <button (click)="end()" type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p>Votre compte a été supprimé.</p>
            </div>
            <div class="modal-footer">
                <button (click)="end()" type="button" class="btn btn-primary">Ok</button>
            </div>
        </div>
    </div>
</div>

