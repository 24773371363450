


    <section class="full-page"
    style="height: 50%;background: linear-gradient(180deg, #000 0%, #373b3c 99.99%, rgba(76, 165, 251, 0.00) 100%)">
    <div class="top-page">
        <div class="aninimated-title">
            <h2 class="text-uppercase heading-light ls-1 ah-headline">
                <span style="font-size: 30px;">Profil HelloTag désactivé :( !</span>

                <!-- <span class="ah-words-wrapper">
                    <b class="is-visible">Plus de connexions</b>
                    <b>Plus de fluidité</b>
                    <b>HelloTag</b>
                </span> -->
            </h2>
        </div>
    </div><!-- End Container -->
</section><!-- End Section -->
<!-- ==============================================
**PROFILE**
=============================================== -->
<section  class="bbs1 bc-light pb-60">
    <div class="container">
        <div class="bottom-page" style="border-radius: 20px;">
            <div class="profile-card-two">
                <header class="profile-card-header">
                    <!-- here’s the avatar -->
                    <a href="assets/images/#">
                        <img src="assets/images/logo2.svg" alt="image">
                    </a>
                    <p style="text-align: center;font-size: 20px;"> Hey HelloTagueur! Le profil a été désactivé par son possesseur !</p>
                </header>



            </div>
        </div>

    </div><!-- End Container -->

</section>