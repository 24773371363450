<section class="home-banner dark-image-bg">
    <div class="container text-center">
        <div class="aninimated-title">
            <h2 class="text-uppercase heading-light ls-1 ah-headline">
                <span>Profil HelloTag désactivé :( !</span>
                <!-- <span class="ah-words-wrapper">
                    <b class="is-visible">Creative Designer</b>
                    <b>Passionate Coder</b>
                    <b>Digital Dreamer</b>
                </span> -->
            </h2>
        </div>
    </div><!-- End Container -->
</section><!-- End Section --> 
<!-- ==============================================
**PROFILE**
=============================================== -->
<section class="bbs1 bc-light pb-60">
    <div class="container">
        <div class="home-profile">
            <div class="profile-card-two">
                <header class="profile-card-header">
                    <!-- here’s the avatar -->
                    <a>
                        <img src="../../../assets/images/logo2.svg" alt="image">
                    </a>
                </header>
                <div class="profile-bio">
                    <div class="ptb-20 text-center">
                        <p>Hey HelloTagueur! Le profil a été désactivé par son possesseur !</p>
                    </div>
            
                </div>
            </div>
        </div>

        <!-- ==============================================
**INTERESTS**
=============================================== -->

    </div><!-- End Container -->

</section><!-- End Section -->
