import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Findme } from '../model/findme';

@Injectable({
  providedIn: 'root'
})
export class FindmeService {

  constructor(private firestore: AngularFirestore) { }

  async getFindme(pid) {
    let x = await this.firestore.collection('findme', ref => ref.where("profilId","==",pid)).get().toPromise();
    let data: Findme = x.docs[0].data() as any;
    // debugger;
    return data;
  }

}