import { Component, OnInit, OnDestroy } from '@angular/core';
import { Profil } from 'src/app/model/profil';
import { ProfilService } from 'src/app/services/profil.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { Social } from 'src/app/model/social';
import { AngularFireAuth } from '@angular/fire/auth';
import { HttpClient } from '@angular/common/http';
import { VCard, VCardEncoding, VCardFormatter } from 'ngx-vcard';
import { AngularFirestore } from '@angular/fire/firestore';
import { ToolsService } from 'src/app/services/tools.service';
import { UserService } from 'src/app/services/user.service';
import { Exchange } from 'src/app/model/exchange';


const buf2hex = (buffer: ArrayBuffer) =>
  [...new Uint8Array(buffer)]
    .map(x => x.toString(16).padStart(2, '0'))
    .join('');

@Component({
  selector: 'app-social',
  templateUrl: './social.component.html',
  styleUrls: ['./social.component.css']
})



export class SocialComponent implements OnInit, OnDestroy {

  backgroundStyle: string;
  btnStyle: string;

  p: Profil = new Profil();
  sms: string;
  tel;
  adresse;
  mail: string;
  id;
  mdp: boolean;
  pw: number;
  message: string;
  verified: boolean = false;
  loaded = true;
  sub;
  vCard: VCard;
  creds;
  sub2;
  user;
  color;
  whatsapp;
  submitted:boolean = false;
  exSuccess=null;


  constructor(private userService: UserService, private toolsService: ToolsService, private http: HttpClient, private store: AngularFirestore, public ngFireAuth: AngularFireAuth, private profilService: ProfilService, private route: ActivatedRoute, private sanitizer: DomSanitizer, private router: Router) { }
  ngOnDestroy(): void {
    this.sub?.unsubscribe();
    this.sub2?.unsubscribe();
  }

  type: 'pro' | 'social';
  async ngOnInit() {
    this.type = this.route.snapshot['data']['type'] // :');
    this.id = this.route.snapshot.params.id;
    await this.genericAuth();
    this.user = await this.userService.getUser(this.creds.user.uid);
    let p = await this.getProfil();
    this.http.get(`https://europe-west2-projetnfc-d9436.cloudfunctions.net/saveViews-1?id=${this.id}`).subscribe(e => {console.log(e)},er => {console.log(er)});

    console.log(this.verified + " " + this.mdp);
    this.loaded = false;
  }

  openExchange(){}



  generateUID(): string {
    return 'uid-' + Math.random().toString(36).substr(2, 9) + '-' + Date.now().toString(36);
}

  async submitForm(event,prenom,nom,entreprise,mail,tel,message) {
    event.preventDefault()
    this.submitted = true;
    let id = this.generateUID();
    let ex = new Exchange(id,nom,prenom,tel,entreprise,message,this.p.id,false,new Date(),this.p.userId,mail);
    try {
      let k = await this.store.collection('exchange').doc(id).set({ ...ex });
      this.exSuccess= true;
    } 
    catch (e) {
      this.submitted = false;
      this.exSuccess= false;

    }
    this.submitted = false;

  }

  async genericAuth() {

    this.creds = await this.ngFireAuth.signInAnonymously();
    let u = await this.userService.getUser(this.creds.user.uid);

    if (!!u) {
      let x = this.toolsService.mergeDeep(u);
      await this.store.collection('users').doc(this.creds.user.uid).update(
        { ...x }
      );
    } else {
      await this.store.collection('users').doc(this.creds.user.uid).set(
        { ...u }
      );
    }

    // this.creds = await this.ngFireAuth.signInWithEmailAndPassword("testzoom1337@gmail.com", "GenericUser1337");
    return this.creds;
  }

  checkSecurity(p: Profil) {
    if (p.social_enabled) {
      if (this.p.social_secure) {
        this.mdp = true;
        this.loaded = false;
        // setTimeout(function () {
        //   document.getElementById("pw").click()
        // }, 1);
        // setTimeout(function () {
        //   document.getElementById("pw").focus()
        // }, 1)
      }
      else {
        this.mdp = false;
      }
    } else {
      this.router.navigate([`../${this.id}`]);
    }
    if (p.findme) {
      this.router.navigate([`findme/${this.id}`]);
    }
  }
  async getProfil() {
    let profil = await this.profilService.getProfil(this.id);
    this.p = profil;
    console.log(this.creds);
    console.log(this.p);
    if (this.p.social_secure)
      this.mdp = true;
    else
      this.mdp = false;
    // setTimeout(function () {
    //   document.getElementById("pw").click()
    // }, 1);
    // setTimeout(function () {
    //   document.getElementById("pw").focus()
    // }, 1);

    this.sub = this.profilService.getFullProfil(this.id, this.type).subscribe(
      async profil => {
        this.p = profil;
        this.checkSecurity(this.p);
        this.vCard = {
          name: {
            firstNames: this.p[this.type].nom,
            lastNames: this.p[this.type].prenom,
          },
          email: [this.p[this.type].mail],
          telephone: [this.p[this.type].tel],
          // photo: {
          //   value: this.p.pro.photo,s
          //   param: {
          //     value: "uri"
          //   }
          // }
        }
        if (this.p.social.banner) {
          this.backgroundStyle = ` url(${this.p.social.banner}), lightgray 50% / cover no-repeat`;
        }
        if (this.p.social.enabled == false) {
          this.router.navigate([`//${this.id}`]);
        }
        this.tel = `tel:${this.p[this.type].tel}`;
        this.sms = `sms:${this.p[this.type].tel}`;
        this.mail = `mailto:${this.p[this.type].mail}`;
        // this.adresse = `https://www.google.com/maps/search/${this.p[this.type].adresse}`;
        this.adresse = `https://www.google.com/maps/search/${this.p[this.type].adresse}`;

        this.whatsapp = `https://wa.me/${this.p.social.whatsapp.link}`
        this.loaded = false;
        if ((this.p['color'] != null || this.p['color'] != undefined) && this.p.color != "") {
          let colorQuery = await this.store.collection('colors', ref => ref.where("couleur", "==", this.p['color'])).get().toPromise();
          this.color = colorQuery.docs[0].data() as any;
          //this.backgroundStyle =  this.color['background'];
          this.btnStyle = this.color['button'];
        } else {
          let colorQuery = await this.store.collection('colors', ref => ref.where("couleur", "==", "bleu")).get().toPromise();
          this.color = colorQuery.docs[0].data() as any;
          //this.backgroundStyle =  this.color['background'];
           this.btnStyle = this.color['button'];
      
        }
        if (this.p.enable == false) {
          this.router.navigate([`/desactiver/${this.id}`]);
        }
      }
    )





    //this.profilService.profil.next(this.p);
  }

  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  downloadAndEncodeImage() {

    this.http.get(this.p.social.photo, { responseType: 'arraybuffer' }).subscribe(
        (data: ArrayBuffer) => {
            const binary = new Uint8Array(data);
            const base64 = this.arrayBufferToBase64(binary);
            return base64; // Si le type MIME n'est pas défini
        },
        error => {
            console.error('Error downloading image:', error);
        }
    );
}


arrayBufferToBase64(buffer: Uint8Array) {
  let binary = '';
  for (let i = 0; i < buffer.length; i++) {
    binary += String.fromCharCode(buffer[i]);
  }
  return btoa(binary);
}

  downloadVcard() {
    // Contenu du fichier vCard
    const vCardData = `
BEGIN:VCARD
VERSION:3.0
FN:${this.p.social.nom}
ORG:${this.p.social.titre}
TEL:${this.p.social.tel}
EMAIL:${this.p.social.mail}
PHOTO;VALUE=BASE64:${this.downloadAndEncodeImage()}
ADR:${this.p.social.adresse}
END:VCARD
`;

    // Créer un objet Blob avec le contenu vCard
    const blob = new Blob([vCardData], { type: 'text/vcard' });

    // Créer un objet URL à partir du Blob
    const blobUrl = window.URL.createObjectURL(blob);

    // Créer un élément d'ancre pour déclencher le téléchargement
    const a = document.createElement('a');
    a.href = blobUrl;
    a.download = 'fiche_contact.vcf';

    // Ajouter l'élément ancre au corps du document
    document.body.appendChild(a);

    // Simuler un clic sur le lien pour déclencher le téléchargement
    a.click();

    // Retirer l'élément ancre du corps du document
    document.body.removeChild(a);

    // Libérer l'URL de l'objet Blob
    window.URL.revokeObjectURL(blobUrl);
  }

  openApp() {
    // window.location.href = `hellotag://${this.id}&${this.p[this.type].nom}`;
    window.location.href = `https://my-hellotag.web.app/${this.id}&social`;

  }

  async sha256(ascii: string) {
    return buf2hex(await window.crypto.subtle.digest("SHA-256", new TextEncoder().encode(ascii)));
  }



  async verif(mdp: HTMLInputElement) {
    this.loaded = true;
    let creds = await this.genericAuth();
    try {
      let x = this.toolsService.mergeDeep(this.user, { [this.id]: { social: mdp.value } });
      await this.store.collection('users').doc(creds.user.uid).update(
        { ...x }
      );
      this.profilService.clear();
      //this.profilService.getProfil(this.id);
      await new Promise(r => setTimeout(r, 1000));
      this.loaded = false;
      this.message = "";
      this.sub2 = this.profilService.getFullProfil(this.id, this.type).subscribe(async profil => {
        this.p = profil;
        this.checkSecurity(this.p);
        this.vCard = {
          name: {
            firstNames: this.p[this.type].nom,
            lastNames: this.p[this.type].prenom,
          },
          email: [this.p[this.type].mail],
          telephone: [this.p[this.type].tel],
          // photo: {
          //   value: this.p.pro.photo,s
          //   param: {
          //     value: "uri"
          //   }
          // }
        }
        if (this.p.social.banner) {
          this.backgroundStyle = `linear-gradient(0deg, black 0%, rgb(7 7 7 / 80%) 100%), url(${this.p.social.banner}), lightgray 50% / cover no-repeat`
        }
        if (this.p.social.enabled == false || this.p.enable == false) {
          this.router.navigate([`//${this.id}`]);
        }
        this.sms = `sms:${this.p[this.type].tel}`;
        this.mail = `mailto:${this.p[this.type].mail}`;
        this.whatsapp = `https://wa.me/${this.p.social.whatsapp.link}`

        if (this.mdp)
          this.verified = this.p[this.type].passcode == mdp.value;
        if (!this.verified) {
          this.mdp = true;
          this.message = "Mot de passe incorrect !"
        }
      }, err => {
        this.mdp = true;
        this.message = "Mot de passe incorrect !";
      })



    } catch (e) {
      this.mdp = true;
      this.message = "Une erreur est survenue, veuillez contacter HelloTag"
    }
  }

  isSocial(rs: any): rs is Social {
    return rs && (rs instanceof Social || (typeof rs == "object" && ['name', 'profilId', 'active', 'link', 'findme'].every(k => k in rs) || (typeof rs == "object" && ['name', 'active', 'link', 'findme'].every(k => k in rs))));
  }

  findRes(): Social[] {
    return Object.values(this.p.social)
      .filter(e => this.isSocial(e))
      .sort((a, b) => (a.rank || 0) - (b.rank || 0)) as Social[];
  }

}
