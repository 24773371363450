    <br><br><br><br><br>
    <p style="text-indent: 0pt;text-align: left;"><br /></p>
    <p class="s1" style="padding-left: 154pt;text-indent: 0pt;line-height: 125%;text-align: center;">POLITIQUE DE
        CONFIDENTIALITÉ HELLOTAG®, l’App</p>
    <p style="text-indent: 0pt;text-align: left;"><br /></p>
    <p style="padding-left: 154pt;text-indent: 0pt;text-align: center;"><a href="mailto:contact@hellotag.fr"
            class="s2">contact@hellotag.fr</a></p>
    <p style="padding-left: 28pt;text-indent: 0pt;text-align: left;"></p>
    <p style="text-indent: 0pt;text-align: left;"><br /></p>
    <p class="s3" style="padding-top: 5pt;padding-left: 84pt;text-indent: 0pt;text-align: center;">PREAMBULE –
        DEFINITIONS</p>
    <p style="text-indent: 0pt;text-align: left;"><br /></p>
    <p style="padding-top: 5pt;padding-left: 34pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Par « <span
            class="s4">Application</span><span class="s5"> </span>», on entend le programme logiciel téléchargeable
        HELLOTAG, développé par HELLOTAG CORPORATION et exécutable à partir du système d’exploitation du smartphone ou
        de la tablette de l’Utilisateur dans le cadre de l’utilisation du Service.</p>
    <p style="text-indent: 0pt;text-align: left;"><br /></p>
    <p style="padding-left: 34pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Par « <span
            class="s4">Conditions d’Utilisation</span><span class="s5"> </span>», on entend les conditions générales
        d’utilisation, lesquelles constituent un accord fixant les droits et obligations des Utilisateurs et les bonnes
        règles d’usage de l’Application.</p>
    <p style="text-indent: 0pt;text-align: left;"><br /></p>
    <p style="padding-left: 34pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Par <span class="s4">« Données
            »</span>, on entend , conformément à l’article 4 du Règlement Général sur la Protection des Données, toute
        information se rapportant à une personne physique identifiée ou identifiable (ci-après dénommée «personne
        concernée») directement ou indirectement, notamment par référence à un identifiant, tel qu&#39;un nom, un numéro
        d&#39;identification, des données de localisation, un identifiant en ligne, ou à un ou plusieurs éléments
        spécifiques propres à son identité physique, physiologique, génétique, psychique, économique, culturelle ou
        sociale.</p>
    <p style="text-indent: 0pt;text-align: left;"><br /></p>
    <p style="padding-left: 34pt;text-indent: 0pt;line-height: 118%;text-align: justify;">Par<span class="s4"> « Fiche
            Professionnelle »</span>, on entend l’ensemble des informations et données professionnelles constituant le «
        profil professionnel » de l’Utilisateur tel qu’il souhaite le communiquer aux tiers dans le cadre de
        l’utilisation des Services.</p>
    <p style="text-indent: 0pt;text-align: left;"><br /></p>
    <p style="padding-left: 34pt;text-indent: 0pt;text-align: justify;">Par <span class="s4">« Fiche Sociale »</span>,
        on entend l’ensemble des informations et données personnelles constituant le</p>
    <p style="padding-top: 2pt;padding-left: 34pt;text-indent: 0pt;line-height: 119%;text-align: justify;">« profil
        social » de l’Utilisateur tel qu’il souhaite le communiquer aux tiers dans le cadre de l’utilisation des
        Services.</p>
    <p style="text-indent: 0pt;text-align: left;"><br /></p>
    <p style="padding-left: 34pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Par <span class="s4">«
            My-hellotag.fr »</span>, on entend le site hébergeant les Pages Profil Internet permettant aux Visiteurs de
        consulter les Profils des Utilisateurs au moyen du lien partagé par le Tag ainsi que par l’Utilisateur lui-même
        lorsqu’il entend visualiser son Profil.</p>
    <p style="text-indent: 0pt;text-align: left;"><br /></p>
    <p style="padding-left: 34pt;text-indent: 0pt;line-height: 118%;text-align: justify;">Par <span class="s4">« Membre
            » ou « Utilisateur »,</span><span class="s5"> </span>on entend toute personne âgée de plus de 15 ans membre
        de la communauté HELLOTAG, titulaire d’un (ou plusieurs) Tag et d’un compte HELLOTAG dédié géré au moyen de
        l’Application.</p>
    <p style="text-indent: 0pt;text-align: left;"><br /></p>
    <p style="padding-left: 34pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Par <span class="s4">« Page
            Profil Internet</span><span class="s5"> </span>», il convient d’entendre la page internet « my-hellotag.fr »
        sur laquelle chaque Visiteur ou Membre sera automatiquement redirigé lors d’une connexion avec le tag d’un
        Membre HELLOTAG, et qui reproduira le profil personnel et/ou professionnel dudit Membre comportant ses
        Coordonnées telles qu’il aura décidé de les partager lors de la configuration de ses Profils en ce inclus les
        liens renvoyant à ses réseaux sociaux.</p>
    <p style="text-indent: 0pt;text-align: left;"><br /></p>
    <p style="padding-left: 34pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Par <span class="s4">« Profil
            »,</span><span class="s5"> </span>on entend l’ensemble des fiches dite « professionnelle » et « sociale » de
        l’Utilisateurs créée par lui, indiquant ses Coordonnées (professionnelles et sociales pour chacune des fiches)
        et destinées à être transmises aux tiers.</p>
    <p style="padding-top: 4pt;padding-left: 34pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Le terme «
        <span class="s4">Service(s)</span><span class="s5"> </span>» désigne l’ensemble des services mis à la
        disposition des Utilisateurs – via l’Application - par HELLOTAG CORPORATION, offrant les moyens techniques de
        partager leurs coordonnées de façon dématérialisée, sécurisée et sous leur entière maîtrise et responsabilité.
    </p>
    <p style="text-indent: 0pt;text-align: left;"><br /></p>
    <p style="padding-left: 34pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Par <span class="s4">« site
            hellotag.fr »</span>, on entend le site internet édité par HELLOTAG CORPORATION ayant pour objet la vente à
        distance de Tag auprès des Utilisateur et permettant la gestion du compte Utilisateur par ce dernier.</p>
    <p style="text-indent: 0pt;text-align: left;"><br /></p>
    <p style="padding-left: 34pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Par <span class="s4">« Tag
            »,</span><span class="s5"> </span><a href="http://www.hellotag.fr/" class="s2" target="_blank">on entend une
            puce NFC HELLOTAG, disponible à la vente sur le site </a><span class="s12">www.hellotag.fr</span> et auprès
        des revendeurs de la Marque, paramétrable grâce à l’Application et permettant à l’Utilisateur de rejoindre la
        communauté HELLOTAG et de bénéficier des Services.</p>
    <p style="text-indent: 0pt;text-align: left;"><br /></p>
    <p style="padding-left: 34pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Par <span class="s4">«
            Visiteur »,</span><span class="s5"> </span>on entend toute personne physique qui, sans être titulaire d’un
        Tag et d’un compte HELLOTAG dédié, reçoit les Coordonnées qui lui sont communiquées au moyen du Tag par
        l’Utilisateur sans qu’il n’ait besoin d’être lui-même membre de ladite communauté et ce en visitant le site
        my-hellotag.fr.</p>
    <p style="text-indent: 0pt;text-align: left;"><br /></p>
    <p class="s3" style="padding-top: 9pt;padding-left: 84pt;text-indent: 0pt;text-align: center;">RESUME</p>
    <p style="text-indent: 0pt;text-align: left;"><br /></p>
    <p style="padding-left: 34pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Pour les besoins de son
        activité, et afin de satisfaire les demandes de ses Utilisateurs, la société HELLOTAG CORPORATION, Société par
        Actions Simplifiées au capital variable, immatriculée au R.C.S de MARSEILLE sous le numéro 902 509 967, et dont
        le siège social est situé au 19 rue du musée 13001 MARSEILLE (ci-après désignée « HELLOTAG ») – en qualité de
        responsable de traitement - est amenée à procéder au traitement de données à caractère personnel des
        Utilisateurs.</p>
    <p style="text-indent: 0pt;text-align: left;"><br /></p>
    <p style="padding-left: 34pt;text-indent: 0pt;text-align: justify;">Le Service fourni par HELLOTAG est destiné aux
        personnes âgées de plus de 15 ans.</p>
    <p style="text-indent: 0pt;text-align: left;"><br /></p>
    <p style="padding-left: 34pt;text-indent: 0pt;line-height: 119%;text-align: justify;">HELLOTAG accorde une
        importance particulière à la protection des Données, à la vie privée de ses Utilisateurs ainsi qu’à la confiance
        que la Communauté HELLOTAG lui accorde dans le cadre de son expérience du service HELLOTAG.</p>
    <p style="text-indent: 0pt;text-align: left;"><br /></p>
    <p style="padding-left: 34pt;text-indent: 0pt;line-height: 119%;text-align: justify;">HELLOTAG traite les Données de
        ses Utilisateurs dans le respect des Lois et de la réglementation en vigueur, et notamment du Règlement Général
        sur la Protection des Données entré en vigueur le 25 mai 2018, de la loi Informatique et Libertés du 6 janvier
        1978 modifiée, et des référentiels édictés et publiés par la CNIL.</p>
    <p style="text-indent: 0pt;text-align: left;"><br /></p>
    <p style="padding-left: 34pt;text-indent: 0pt;line-height: 119%;text-align: justify;">La présente politique de
        confidentialité (ci-après dénommée « la Politique ») a pour vocation d’informer des modalités et conditions de
        traitement des Données traitées le cadre de l’utilisation du Service et de l’Application, des caractéristiques
        dudit traitement, ainsi que des droits dont les Utilisateurs jouissent relativement à ce traitement.</p>
    <p style="text-indent: 0pt;text-align: left;"><br /></p>
    <p style="padding-left: 34pt;text-indent: 0pt;text-align: justify;">Les Utilisateurs sont invités à lire
        attentivement la Politique.</p>
    <p style="text-indent: 0pt;text-align: left;"><br /></p>
    <p style="padding-left: 34pt;text-indent: 0pt;line-height: 119%;text-align: justify;"><a
            href="mailto:contact@hellotag.fr" class="s2" target="_blank">Les questions et commentaires relatives à la
            présente Politique peuvent être envoyés par e-mail à l’adresse </a><a href="mailto:contact@hellotag.fr"
            class="a" target="_blank">contact@hellotag.fr</a><a href="mailto:contact@hellotag.fr" class="s2"
            target="_blank">.</a></p>
    <p style="text-indent: 0pt;text-align: left;"><br /></p>
    <p style="padding-left: 34pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Si après avoir contacté
        l’équipe HELLOTAG, vous constatez que vos droits « Informatiques et libertés » ne sont pas respectés, vous
        pourrez adresser une réclamation à la Commission Nationale de l’Informatique et des Libertés (la « CNIL »), 3
        place de Fontenoy, 75007 Paris.</p>
    <p class="s3" style="padding-top: 4pt;padding-left: 34pt;text-indent: 0pt;line-height: 125%;text-align: left;">
        ARTICLE 1- RESPONSABLE DU TRAITEMENT DES DONNÉES À CARACTÈRE PERSONNEL ET SOUS-TRAITANCE</p>
    <p style="text-indent: 0pt;text-align: left;"><br /></p>
    <p style="padding-top: 5pt;padding-left: 34pt;text-indent: 0pt;line-height: 119%;text-align: justify;">La société
        responsable du traitement des Données vous concernant, sur l’Application, est la société HELLOTAG CORPORATION,
        Société par Actions Simplifiées au capital variable, immatriculée au R.C.S de MARSEILLE sous le numéro 902 509
        967, et dont le siège social est situé au 19 rue du musée 13001 MARSEILLE, représentée par son Président dûment
        habilité.</p>
    <p style="text-indent: 0pt;text-align: left;"><br /></p>
    <p style="padding-left: 34pt;text-indent: 0pt;line-height: 119%;text-align: justify;">HELLOTAG CORPORATION est
        l’entité qui détermine les finalités et les moyens du traitement de l’ensemble des données personnelles
        collectées dans le cadre de l’utilisation de son Service.</p>
    <p style="text-indent: 0pt;text-align: left;"><br /></p>
    <p style="padding-left: 34pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Afin de fournir un service
        optimal à ses Utilisateurs, HELLOTAG CORPORATION s’est assurée les services de prestataires informatiques qui
        lui fournissent les services techniques nécessaire à l’hébergement, la gestion, le maintien et la sécurité de
        l’Application sur les réseaux – ci-après « les Sous-traitant ».</p>
    <p style="text-indent: 0pt;text-align: left;"><br /></p>
    <p class="s3" style="padding-left: 34pt;text-indent: 0pt;text-align: justify;">Hébergement de l’Application :</p>
    <p style="padding-top: 2pt;padding-left: 34pt;text-indent: 0pt;line-height: 118%;text-align: justify;">L’Application
        HELLOTAG est hébergée sous la responsabilité de la plateforme Google Firebase, exploitée par la Société Google
        Commerce Limited, et dont le siège social est sis à l’adresse Gordon House, Barrow Street, Dublin 4, Ireland,
        laquelle est l’entité qui traite les Données issues de l’Application au nom et pour le compte d’HELLOTAG
        CORPORATION, en sa qualité de sous-traitant.</p>
    <p style="text-indent: 0pt;text-align: left;"><br /></p>
    <p class="s3" style="padding-left: 34pt;text-indent: 0pt;text-align: justify;">Prestataire bancaire :</p>
    <p style="padding-top: 2pt;padding-left: 34pt;text-indent: 0pt;text-align: left;">Paypal : PayPal (Europe) S.à r.l.
        et Cie, S.C.A., 22-24 Boulevard Royal L-2449, Luxembourg</p>
    <p style="text-indent: 0pt;text-align: left;"><br /></p>
    <p class="s3" style="padding-left: 34pt;text-indent: 0pt;text-align: left;">Prestataire hébergement adresse mail et
        noms de domaine :</p>
    <p style="padding-top: 2pt;padding-left: 34pt;text-indent: 0pt;text-align: left;">Godaddy.com</p>
    <p style="text-indent: 0pt;text-align: left;"><br /></p>
    <p class="s3" style="padding-left: 34pt;text-indent: 0pt;text-align: left;">Prestataire hébergement du site
        my-hellotag.fr / fournisseurs de liens :</p>
    <p style="padding-top: 2pt;padding-left: 34pt;text-indent: 0pt;text-align: left;">Firebase Google Société Google
        Commerce Limited - Gordon House, Barrow Street, Dublin 4, Ireland</p>
    <p style="text-indent: 0pt;text-align: left;"><br /></p>
    <p class="s3" style="padding-left: 34pt;text-indent: 0pt;text-align: left;">ARTICLE 2- PRINCIPES APPLICABLES AU
        TRAITEMENT DES DONNÉES</p>
    <p style="text-indent: 0pt;text-align: left;"><br /></p>
    <p style="padding-top: 5pt;padding-left: 34pt;text-indent: 0pt;line-height: 119%;text-align: justify;">HELLOTAG
        s’efforce de mettre en place une politique de Données assurant une sécurité accrue de celles-ci, en ayant
        recours à toute mesure permettant de garantir à ses clients et utilisateurs un niveau de protection élevé. À ce
        titre, HELLOTAG fait ses meilleurs efforts pour œuvrer vers une conformité optimale de ses Services avec la
        Législation applicable en matière de protection des données à caractère personnel.</p>
    <p style="text-indent: 0pt;text-align: left;"><br /></p>
    <p style="padding-left: 34pt;text-indent: 0pt;line-height: 119%;text-align: justify;">HELLOTAG s’efforce, dans ce
        cadre, à développer une politique interne de compliance et de consécration des principes de Privacy by design et
        de Privacy by default dans le développement de son Application et de ses Services.</p>
    <p style="text-indent: 0pt;text-align: left;"><br /></p>
    <p style="padding-left: 34pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Toutefois, l’Utilisateur
        reconnaît que les avancées technologiques et le développement de manœuvres portant atteinte à la cyber-sécurité
        ne permettent pas à HELLOTAG de garantir une obligation de résultat quant à la sécurité des Données, néanmoins,
        elle s’engage à user de tout moyen technique, tout prestataire et tout procédé à sa portée pour assurer la
        sécurité la plus adaptée.</p>
    <p style="padding-top: 4pt;padding-left: 34pt;text-indent: 0pt;line-height: 119%;text-align: justify;">L’ensemble
        des Données sont collectées et traitées pour le compte de la société HELLOTAG, responsable de traitement, de
        <span class="s5">manière loyale et transparente</span>, aucune collecte de Données n’étant effectuée à l’insu de
        l’Utilisateur ou du Visiteur.</p>
    <p style="text-indent: 0pt;text-align: left;"><br /></p>
    <p style="padding-left: 34pt;text-indent: 0pt;line-height: 119%;text-align: justify;">HELLOTAG traite les Données
        exclusivement et strictement dans le cadre des finalités de traitement déterminées, explicites et légitimes
        listées dans le cadre de la présente Politique et s’engage à ce qu’elles ne soient pas détournées et/ou
        utilisées ultérieurement de manière incompatible avec ces finalités.</p>
    <p style="text-indent: 0pt;text-align: left;"><br /></p>
    <p style="padding-left: 34pt;text-indent: 0pt;line-height: 119%;text-align: justify;">En tout état de cause, si
        HELLOTAG venait à modifier les finalités de traitement des Données et/ou à collaborer avec un service tiers dans
        ce cadre, celle-ci s’engage à en informer l’Utilisateur et, le cas échéant, le Visiteur et à recueillir son
        consentement sur le nouveau traitement.</p>
    <p style="text-indent: 0pt;text-align: left;"><br /></p>
    <p style="padding-left: 34pt;text-indent: 0pt;text-align: justify;">Les traitements effectués par HELLOTAG sont
        conformément à la règlementation en vigueur, à savoir :</p>
    <p style="text-indent: 0pt;text-align: left;"><br /></p>
    <ul id="l1">
        <li data-list-text="-">
            <p style="padding-left: 70pt;text-indent: -18pt;line-height: 115%;text-align: justify;">L’exécution des
                accords contractuels conclus entre HELLOTAG, ses clients et les Utilisateurs (contrat de vente et
                conditions générales d’utilisation de l’Application), aux fins de vous fournir notre Service, et/ou ;
            </p>
        </li>
        <li data-list-text="-">
            <p style="padding-left: 70pt;text-indent: -18pt;text-align: justify;">Le respect d’une obligation légale,
                et/ou ;</p>
        </li>
        <li data-list-text="-">
            <p style="padding-top: 1pt;padding-left: 70pt;text-indent: -18pt;line-height: 111%;text-align: justify;">
                Votre consentement préalable à l’utilisation de vos Données pour une ou plusieurs finalités explicites,
                et/ou,</p>
        </li>
        <li data-list-text="-">
            <p style="padding-left: 70pt;text-indent: -18pt;line-height: 112%;text-align: justify;">L’existence d’un
                intérêt légitime à utiliser vos Données, justifié notamment par des raisons commerciales.</p>
        </li>
    </ul>
    <p style="text-indent: 0pt;text-align: left;"><br /></p>
    <p style="padding-left: 34pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Au titre de circonstances
        exceptionnelles et conformément à la Loi, HELLOTAG CORPORATION, en qualité d’hébergeur des Services au sens de
        la LCEN du 21 juin 2004 et de responsable de traitement au sens du RGPD, pourra être amené à extraire et
        divulguer les Données, lorsque cela sera exigé par une autorité judiciaire ou administrative compétente sous
        réserve du respect des principes fondamentaux, des libertés individuelles et des dispositions du Code de
        procédure Pénale.</p>
    <p style="text-indent: 0pt;text-align: left;"><br /></p>
    <p class="s3" style="padding-left: 34pt;text-indent: 0pt;text-align: justify;">Lieux d’hébergement des données :</p>
    <p style="text-indent: 0pt;text-align: left;"><br /></p>
    <p style="padding-left: 34pt;text-indent: 0pt;line-height: 119%;text-align: justify;">HELLOTAG CORPORATION recourt à
        un prestataire technique, sous-traitant, lequel assure le service d’hébergement de l’Application et des Données
        au sein de l’Union Européenne. Si HELLOTAG était contrainte de procéder à un quelconque transfert de données en
        dehors de l’Union Européenne ledit transfert serait nécessité par l’une des hypothèse suivantes :</p>
    <p style="text-indent: 0pt;text-align: left;"><br /></p>
    <ol id="l2">
        <li data-list-text="1.">
            <p style="padding-left: 70pt;text-indent: -18pt;text-align: justify;">À la sauvegarde de la vie de
                l’Utilisateur ;</p>
        </li>
        <li data-list-text="2.">
            <p style="padding-top: 2pt;padding-left: 70pt;text-indent: -18pt;text-align: justify;">À la sauvegarde de
                l&#39;intérêt public ;</p>
        </li>
        <li data-list-text="3.">
            <p style="padding-top: 2pt;padding-left: 70pt;text-indent: -18pt;line-height: 119%;text-align: justify;">Au
                respect d&#39;obligations permettant d&#39;assurer la constatation, l&#39;exercice ou la défense
                d&#39;un droit en justice ;</p>
        </li>
        <li data-list-text="4.">
            <p style="padding-left: 70pt;text-indent: -18pt;line-height: 119%;text-align: justify;">À la consultation,
                dans des conditions régulières, d&#39;un registre public qui, en vertu de dispositions législatives ou
                réglementaires, est destiné à l&#39;information du public et est ouvert à la consultation de celui-ci ou
                de toute personne justifiant d&#39;un intérêt légitime ;</p>
        </li>
        <li data-list-text="5.">
            <p style="padding-left: 70pt;text-indent: -18pt;line-height: 119%;text-align: justify;">À l&#39;exécution
                d&#39;un contrat entre le responsable du traitement et l&#39;intéressé, ou de mesures précontractuelles
                prises à la demande de celui-ci ;</p>
        </li>
        <li data-list-text="6.">
            <p style="padding-left: 70pt;text-indent: -18pt;line-height: 119%;text-align: justify;">À la conclusion ou à
                l&#39;exécution d&#39;un contrat conclu ou à conclure, dans l&#39;intérêt de la personne concernée,
                entre le responsable du traitement et un tiers.</p>
            <p style="text-indent: 0pt;text-align: left;"><br /></p>
            <p style="padding-left: 34pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Tout transfert hors
                Union Européenne des Données non motivé par les hypothèses visées ci-avant sera soumis à l’accord
                préalable et écrit de l’Utilisateur.</p>
            <p class="s3" style="padding-top: 4pt;padding-left: 34pt;text-indent: 0pt;text-align: left;">ARTICLE 3 – LES
                DONNÉES TRAITEES DANS LE CADRE DE L’UTILISATION DU SERVICE</p>
            <p style="text-indent: 0pt;text-align: left;"><br /></p>
            <p class="s3" style="padding-top: 6pt;padding-left: 34pt;text-indent: 0pt;text-align: justify;">Dans le
                cadre de l’Application HELLOTAG :</p>
            <p style="padding-top: 2pt;padding-left: 34pt;text-indent: 0pt;line-height: 119%;text-align: justify;">
                L’Utilisateur est amené à communiquer à HELLOTAG les Données nécessaire à l’utilisation de l’Application
                et des Services fournis par elle (1), et également certaines Données de connexion et d’utilisation
                collectées de manière indirecte (2).</p>
            <p style="text-indent: 0pt;text-align: left;"><br /></p>
            <p style="padding-left: 34pt;text-indent: 0pt;line-height: 119%;text-align: justify;">A toutes fins utiles,
                il est rappelé que les Services fournis par HELLOTAG sont destinés exclusivement aux personnes âgées de
                plus de 15 ans, le responsable de traitement s’interdisant de traiter sciemment des Données et
                informations concernant des mineurs de moins de 15 ans.</p>
            <p style="text-indent: 0pt;text-align: left;"><br /></p>
            <ol id="l3">
                <li data-list-text="1.">
                    <p class="s1" style="padding-left: 91pt;text-indent: -14pt;line-height: 125%;text-align: left;">Les
                        données collectées directement auprès des Utilisateurs dans le cadre de l’utilisation de
                        l’Application HELLOTAG</p>
                    <p style="text-indent: 0pt;text-align: left;"><br /></p>
                    <p style="padding-left: 34pt;text-indent: 0pt;line-height: 119%;text-align: justify;">L’Utilisateur
                        du Service, afin de bénéficier dudit Service et que celui-ci puisse répondre à ses besoins,
                        confie à HELLOTAG des Données le concernant. Ainsi, lesdites Données font l’objet d’un
                        traitement par HELLOTAG.</p>
                    <p
                        style="padding-top: 1pt;padding-left: 34pt;text-indent: 0pt;line-height: 27pt;text-align: justify;">
                        Plus précisément, les Données traitées par HELLOTAG dans ce cadre, sont les suivantes :
                        <u>Nécessaires à l’identification de l’Utilisateur sur l’Application et sur le Site hellotag.fr
                            :</u></p>
                    <ul id="l4">
                        <li data-list-text="">
                            <p style="padding-left: 70pt;text-indent: -18pt;line-height: 11pt;text-align: justify;">Vos
                                identifiants de connexion lors de toute création de compte, en ce inclus votre e-mail de
                            </p>
                            <p style="padding-top: 2pt;padding-left: 70pt;text-indent: 0pt;text-align: justify;">
                                connexion ;</p>
                            <p style="text-indent: 0pt;text-align: left;"><br /></p>
                            <p class="s7" style="padding-left: 34pt;text-indent: 0pt;text-align: justify;">Nécessaires à
                                la création des Profils :</p>
                            <p style="text-indent: 0pt;text-align: left;"><br /></p>
                        </li>
                        <li data-list-text="">
                            <p style="padding-left: 70pt;text-indent: -18pt;line-height: 117%;text-align: justify;">Du
                                contenu permettant d’identifier directement ou indirectement l’Utilisateur tel que les
                                photos, images, logo, designs destinés à illustrer et identifier le Profil ;</p>
                        </li>
                        <li data-list-text="">
                            <p style="padding-left: 70pt;text-indent: -18pt;line-height: 119%;text-align: justify;">Des
                                informations de base nécessaires à l’établissement des Profils telles que votre âge, vos
                                noms, prénoms ou / et pseudonyme, sexe, votre numéro de téléphone professionnel ou
                                personnel, votre adresse postale professionnelle, votre numéro de téléphone
                                professionnel et/ou personnel, votre email professionnel et/ou personnel ainsi que votre
                                profession et/ou votre niveau d’échelon au sein de votre entreprise ;</p>
                        </li>
                        <li data-list-text="">
                            <p style="padding-left: 70pt;text-indent: -18pt;line-height: 11pt;text-align: justify;">Des
                                liens renvoyant aux profils de l’Utilisateur sur les réseaux sociaux tels que Facebook,
                            </p>
                            <p style="padding-top: 1pt;padding-left: 70pt;text-indent: 0pt;text-align: justify;">
                                Instagram, TikTok, Snapchat, Twitter et à leurs contenus ;</p>
                        </li>
                        <li data-list-text="">
                            <p
                                style="padding-top: 1pt;padding-left: 70pt;text-indent: -18pt;line-height: 117%;text-align: justify;">
                                Des liens renvoyant aux pages de l’Utilisateurs sur les sites de partages de contenus en
                                ligne et lesdits contenus (YouTube®, Pinterest®, Vimeo®) ;</p>
                        </li>
                        <li data-list-text="">
                            <p style="padding-left: 70pt;text-indent: -18pt;line-height: 118%;text-align: justify;">Des
                                liens renvoyant aux applications tierces proposant des services de messagerie et de
                                téléphonie (Signal®, WhatApp®, Telegram® notamment) ou de paiement en ligne tels que
                                PayPal, Lydia, Summup notamment ;</p>
                        </li>
                        <li data-list-text="">
                            <p style="padding-left: 70pt;text-indent: -18pt;line-height: 118%;text-align: justify;">Des
                                fichiers numériques composé de tout document que l’Utilisateur entend joindre à sa fiche
                                professionnelle à destination de ses contacts tiers et pouvant contenir des données
                                personnelles ;</p>
                        </li>
                        <li data-list-text="">
                            <p style="padding-left: 70pt;text-indent: -18pt;line-height: 118%;text-align: justify;">Des
                                informations supplémentaires, telle qu’une biographie ou tout autre écrit ou information
                                de votre choix et rédigé à votre initiative, et pouvant être considérées comme des
                                données personnelles.</p>
                            <p
                                style="padding-top: 12pt;padding-left: 34pt;text-indent: 0pt;line-height: 119%;text-align: justify;">
                                À ce titre, il est précisé que dans le cadre du fonctionnement du Service, HELLOTAG ne
                                requiert aucune de l’Utilisateur, la communication de données considérées comme «
                                sensibles » ou</p>
                            <p style="padding-left: 34pt;text-indent: 0pt;text-align: justify;">« spéciales » telle que
                                par exemple ses croyances religieuses, l’orientation et/ou la vie sexuelle,</p>
                            <p
                                style="padding-top: 4pt;padding-left: 34pt;text-indent: 0pt;line-height: 119%;text-align: justify;">
                                conviction philosophique, opinion politique, prétendues origine raciale ou ethnique,
                                données de santé (en ce compris le numéro de sécurité sociale), appartenance syndicale,
                                données génétiques ou biométriques.</p>
                            <p style="text-indent: 0pt;text-align: left;"><br /></p>
                            <p style="padding-left: 34pt;text-indent: 0pt;line-height: 119%;text-align: justify;">
                                Toutefois, l’Utilisateur est libre de communiquer les données qu’il souhaite dans le
                                cadre de l’utilisation de l’App, le cas échéant, ce dernier reconnaît qu’en choisissant
                                de fournir lesdites données, il autorise expressément HELLOTAG à les traiter et en
                                conséquence, à les faire figurer sur son Profil destiné à être communiqué aux tiers de
                                son choix au moyen du Tag.</p>
                            <p style="text-indent: 0pt;text-align: left;"><br /></p>
                            <p style="padding-left: 34pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Les
                                données ci-dessus mentionnées sont uniquement collectées et stockées pour vous permettre
                                d’utiliser le Service et d’en tirer le meilleur profit, et ce, pour la seule durée de
                                votre usage de celui-ci, sans qu’aucune autre forme de traitement ne soit opéré par
                                HELLOTAG.</p>
                            <p style="text-indent: 0pt;text-align: left;"><br /></p>
                            <p style="padding-left: 34pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Il est
                                par ailleurs précisé qu’en sa qualité d’hébergeur et de fournisseur du Service, HELLOTAG
                                n’exerce aucun contrôle ou aucune surveillance sur les contenus téléversés par les
                                Utilisateurs et n’a donc pas connaissances des Données ainsi transmises. Aucun
                                croisement ni transfert de Données ne sera ainsi opéré par HELLOTAG sans votre
                                consentement.</p>
                            <p style="text-indent: 0pt;text-align: left;"><br /></p>
                        </li>
                    </ul>
                </li>
                <li data-list-text="2.">
                    <p class="s1" style="padding-left: 91pt;text-indent: -14pt;text-align: left;">Les Données collectées
                        de manière indirecte lors de l’utilisation de l’Application</p>
                </li>
            </ol>
        </li>
    </ol>
    <p style="text-indent: 0pt;text-align: left;"><br /></p>
    <p style="padding-left: 34pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Lorsque vous utilisez et
        interagissez avec l’Application, HELLOTAG est susceptible de collecter automatiquement certaines informations,
        notamment grâce à la technologie des cookies, en ce compris :</p>
    <p style="text-indent: 0pt;text-align: left;"><br /></p>
    <ul id="l5">
        <li data-list-text="">
            <p style="padding-left: 70pt;text-indent: -18pt;line-height: 118%;text-align: justify;">Des informations
                concernant votre smartphone ou tablette et votre environnement de connexion, à savoir l’adresse IP, le
                système d’exploitation, la plateforme mobile, les rapports d’erreur et données d’exécution ;</p>
        </li>
        <li data-list-text="">
            <p style="padding-left: 70pt;text-indent: -18pt;line-height: 117%;text-align: justify;">Des informations
                relatives à votre authentification au sein de l’Application à savoir mot de passe, adresse mail et
                numéro de téléphone ;</p>
        </li>
        <li data-list-text="">
            <p style="padding-left: 70pt;text-indent: -18pt;line-height: 117%;text-align: justify;">Des informations
                nécessaires à la distribution de l’application à savoir votre nom d’utilisateur, votre adresse mail
                associée, l’UDID IOS et/ou vos identifiants Android.</p>
        </li>
    </ul>
    <p style="text-indent: 0pt;text-align: left;"><br /></p>
    <p class="s8" style="padding-top: 10pt;padding-left: 84pt;text-indent: 0pt;text-align: center;">TABLEAU DES
        TRAITEMENTS OPÉRÉS DANS LE CADRE DE L’APPLICATION :</p>
    <p style="text-indent: 0pt;text-align: left;"><br /></p>
    <p style="text-indent: 0pt;text-align: left;"><br /></p>
    <table style="border-collapse:collapse;margin-left:6.0078pt" cellspacing="0">
        <tr style="height:28pt">
            <td
                style="width:36pt;border-top-style:solid;border-top-width:1pt;border-top-color:#B4C6E7;border-left-style:solid;border-left-width:1pt;border-left-color:#B4C6E7;border-bottom-style:solid;border-bottom-width:2pt;border-bottom-color:#8EAADB;border-right-style:solid;border-right-width:1pt;border-right-color:#B4C6E7">
                <p class="s9"
                    style="padding-top: 1pt;padding-left: 11pt;padding-right: 10pt;text-indent: 0pt;text-align: center;">
                    N°</p>
            </td>
            <td
                style="width:164pt;border-top-style:solid;border-top-width:1pt;border-top-color:#B4C6E7;border-left-style:solid;border-left-width:1pt;border-left-color:#B4C6E7;border-bottom-style:solid;border-bottom-width:2pt;border-bottom-color:#8EAADB;border-right-style:solid;border-right-width:1pt;border-right-color:#B4C6E7">
                <p class="s9" style="padding-top: 1pt;padding-left: 7pt;text-indent: 0pt;text-align: left;">
                    Traitement/Finalité du traitement</p>
            </td>
            <td
                style="width:105pt;border-top-style:solid;border-top-width:1pt;border-top-color:#B4C6E7;border-left-style:solid;border-left-width:1pt;border-left-color:#B4C6E7;border-bottom-style:solid;border-bottom-width:2pt;border-bottom-color:#8EAADB;border-right-style:solid;border-right-width:1pt;border-right-color:#B4C6E7">
                <p class="s9" style="padding-top: 1pt;padding-left: 8pt;text-indent: 0pt;text-align: left;">Données
                    collectées</p>
            </td>
            <td
                style="width:55pt;border-top-style:solid;border-top-width:1pt;border-top-color:#B4C6E7;border-left-style:solid;border-left-width:1pt;border-left-color:#B4C6E7;border-bottom-style:solid;border-bottom-width:2pt;border-bottom-color:#8EAADB;border-right-style:solid;border-right-width:1pt;border-right-color:#B4C6E7">
                <p class="s9" style="padding-top: 1pt;padding-left: 17pt;text-indent: 0pt;text-align: left;">Base</p>
                <p class="s9" style="padding-top: 2pt;padding-left: 14pt;text-indent: 0pt;text-align: left;">légale</p>
            </td>
            <td
                style="width:164pt;border-top-style:solid;border-top-width:1pt;border-top-color:#B4C6E7;border-left-style:solid;border-left-width:1pt;border-left-color:#B4C6E7;border-bottom-style:solid;border-bottom-width:2pt;border-bottom-color:#8EAADB;border-right-style:solid;border-right-width:1pt;border-right-color:#B4C6E7">
                <p class="s9" style="padding-top: 1pt;padding-left: 31pt;text-indent: 0pt;text-align: left;">Durée de
                    conservation</p>
            </td>
        </tr>
        <tr style="height:70pt">
            <td
                style="width:36pt;border-top-style:solid;border-top-width:2pt;border-top-color:#8EAADB;border-left-style:solid;border-left-width:1pt;border-left-color:#B4C6E7;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#B4C6E7;border-right-style:solid;border-right-width:1pt;border-right-color:#B4C6E7">
                <p style="text-indent: 0pt;text-align: left;"><br /></p>
                <p class="s9" style="text-indent: 0pt;text-align: center;">1</p>
            </td>
            <td
                style="width:164pt;border-top-style:solid;border-top-width:2pt;border-top-color:#8EAADB;border-left-style:solid;border-left-width:1pt;border-left-color:#B4C6E7;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#B4C6E7;border-right-style:solid;border-right-width:1pt;border-right-color:#B4C6E7">
                <p class="s10" style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: left;">Gestion
                    des authentifications sécurisées des utilisateurs</p>
                <p class="s10" style="padding-left: 5pt;text-indent: 0pt;text-align: left;">Gestion des comptes
                    utilisateurs</p>
            </td>
            <td
                style="width:105pt;border-top-style:solid;border-top-width:2pt;border-top-color:#8EAADB;border-left-style:solid;border-left-width:1pt;border-left-color:#B4C6E7;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#B4C6E7;border-right-style:solid;border-right-width:1pt;border-right-color:#B4C6E7">
                <p class="s10"
                    style="padding-left: 5pt;padding-right: 31pt;text-indent: 0pt;line-height: 119%;text-align: left;">
                    Adresse IP, Mot de passe, adresse mail, numéro de</p>
                <p class="s10" style="padding-left: 5pt;text-indent: 0pt;line-height: 12pt;text-align: left;">téléphone
                </p>
            </td>
            <td
                style="width:55pt;border-top-style:solid;border-top-width:2pt;border-top-color:#8EAADB;border-left-style:solid;border-left-width:1pt;border-left-color:#B4C6E7;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#B4C6E7;border-right-style:solid;border-right-width:1pt;border-right-color:#B4C6E7">
                <p class="s10"
                    style="padding-left: 5pt;padding-right: 5pt;text-indent: 0pt;line-height: 119%;text-align: left;">
                    Exécution du contrat</p>
            </td>
            <td
                style="width:164pt;border-top-style:solid;border-top-width:2pt;border-top-color:#8EAADB;border-left-style:solid;border-left-width:1pt;border-left-color:#B4C6E7;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#B4C6E7;border-right-style:solid;border-right-width:1pt;border-right-color:#B4C6E7">
                <p class="s10" style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: left;">Quelques
                    semaines pour les adresses IP</p>
                <p class="s10" style="padding-left: 5pt;text-indent: 0pt;text-align: left;">Pour les autres
                    informations, 180</p>
                <p class="s10" style="padding-left: 5pt;text-indent: 0pt;line-height: 14pt;text-align: left;">jours
                    après la suppression de votre compte utilisateur</p>
            </td>
        </tr>
        <tr style="height:83pt">
            <td
                style="width:36pt;border-top-style:solid;border-top-width:1pt;border-top-color:#B4C6E7;border-left-style:solid;border-left-width:1pt;border-left-color:#B4C6E7;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#B4C6E7;border-right-style:solid;border-right-width:1pt;border-right-color:#B4C6E7">
                <p style="text-indent: 0pt;text-align: left;"><br /></p>
                <p class="s9" style="text-indent: 0pt;text-align: center;">2</p>
            </td>
            <td
                style="width:164pt;border-top-style:solid;border-top-width:1pt;border-top-color:#B4C6E7;border-left-style:solid;border-left-width:1pt;border-left-color:#B4C6E7;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#B4C6E7;border-right-style:solid;border-right-width:1pt;border-right-color:#B4C6E7">
                <p class="s10"
                    style="padding-left: 5pt;padding-right: 1pt;text-indent: 0pt;line-height: 119%;text-align: left;">
                    Distribution de l’Application auprès des utilisateurs</p>
            </td>
            <td
                style="width:105pt;border-top-style:solid;border-top-width:1pt;border-top-color:#B4C6E7;border-left-style:solid;border-left-width:1pt;border-left-color:#B4C6E7;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#B4C6E7;border-right-style:solid;border-right-width:1pt;border-right-color:#B4C6E7">
                <p class="s10" style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: left;">Adresse
                    IP, adresse mail, nom d’utilisateur, UDID IOS, identifiant Android, système</p>
                <p class="s10" style="padding-left: 5pt;text-indent: 0pt;line-height: 12pt;text-align: left;">
                    d’exploitation</p>
            </td>
            <td
                style="width:55pt;border-top-style:solid;border-top-width:1pt;border-top-color:#B4C6E7;border-left-style:solid;border-left-width:1pt;border-left-color:#B4C6E7;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#B4C6E7;border-right-style:solid;border-right-width:1pt;border-right-color:#B4C6E7">
                <p class="s10"
                    style="padding-left: 5pt;padding-right: 5pt;text-indent: 0pt;line-height: 119%;text-align: left;">
                    Exécution du contrat</p>
            </td>
            <td
                style="width:164pt;border-top-style:solid;border-top-width:1pt;border-top-color:#B4C6E7;border-left-style:solid;border-left-width:1pt;border-left-color:#B4C6E7;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#B4C6E7;border-right-style:solid;border-right-width:1pt;border-right-color:#B4C6E7">
                <p class="s10" style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: left;">180 jours
                    après la suppression de votre compte utilisateur</p>
            </td>
        </tr>
    </table>
    <table style="border-collapse:collapse;margin-left:6.0078pt" cellspacing="0">
        <tr style="height:55pt">
            <td
                style="width:36pt;border-top-style:solid;border-top-width:1pt;border-top-color:#B4C6E7;border-left-style:solid;border-left-width:1pt;border-left-color:#B4C6E7;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#B4C6E7;border-right-style:solid;border-right-width:1pt;border-right-color:#B4C6E7">
                <p style="text-indent: 0pt;text-align: left;"><br /></p>
                <p class="s9" style="text-indent: 0pt;text-align: center;">3</p>
            </td>
            <td
                style="width:164pt;border-top-style:solid;border-top-width:1pt;border-top-color:#B4C6E7;border-left-style:solid;border-left-width:1pt;border-left-color:#B4C6E7;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#B4C6E7;border-right-style:solid;border-right-width:1pt;border-right-color:#B4C6E7">
                <p class="s10" style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: left;">Gestion
                    des plantages de l’Application et des pannes</p>
            </td>
            <td
                style="width:105pt;border-top-style:solid;border-top-width:1pt;border-top-color:#B4C6E7;border-left-style:solid;border-left-width:1pt;border-left-color:#B4C6E7;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#B4C6E7;border-right-style:solid;border-right-width:1pt;border-right-color:#B4C6E7">
                <p class="s10"
                    style="padding-left: 5pt;padding-right: 3pt;text-indent: 0pt;line-height: 119%;text-align: left;">
                    Adresse mail, rapport d’erreur, rapport d’exécution,</p>
                <p class="s10" style="padding-left: 5pt;text-indent: 0pt;line-height: 12pt;text-align: left;">Adresse IP
                </p>
            </td>
            <td
                style="width:55pt;border-top-style:solid;border-top-width:1pt;border-top-color:#B4C6E7;border-left-style:solid;border-left-width:1pt;border-left-color:#B4C6E7;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#B4C6E7;border-right-style:solid;border-right-width:1pt;border-right-color:#B4C6E7">
                <p class="s10"
                    style="padding-left: 5pt;padding-right: 5pt;text-indent: 0pt;line-height: 119%;text-align: left;">
                    Exécution du contrat</p>
            </td>
            <td
                style="width:164pt;border-top-style:solid;border-top-width:1pt;border-top-color:#B4C6E7;border-left-style:solid;border-left-width:1pt;border-left-color:#B4C6E7;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#B4C6E7;border-right-style:solid;border-right-width:1pt;border-right-color:#B4C6E7">
                <p class="s10" style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: left;">90 jours
                    après le plantage ou la panne</p>
            </td>
        </tr>
        <tr style="height:69pt">
            <td
                style="width:36pt;border-top-style:solid;border-top-width:1pt;border-top-color:#B4C6E7;border-left-style:solid;border-left-width:1pt;border-left-color:#B4C6E7;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#B4C6E7;border-right-style:solid;border-right-width:1pt;border-right-color:#B4C6E7">
                <p style="text-indent: 0pt;text-align: left;"><br /></p>
                <p class="s9" style="text-indent: 0pt;text-align: center;">4</p>
            </td>
            <td
                style="width:164pt;border-top-style:solid;border-top-width:1pt;border-top-color:#B4C6E7;border-left-style:solid;border-left-width:1pt;border-left-color:#B4C6E7;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#B4C6E7;border-right-style:solid;border-right-width:1pt;border-right-color:#B4C6E7">
                <p class="s10"
                    style="padding-left: 5pt;padding-right: 4pt;text-indent: 0pt;line-height: 119%;text-align: justify;">
                    Hébergement des Données transmises par les utilisateurs dans le cadre de l’utilisation de
                    l’Application</p>
            </td>
            <td
                style="width:105pt;border-top-style:solid;border-top-width:1pt;border-top-color:#B4C6E7;border-left-style:solid;border-left-width:1pt;border-left-color:#B4C6E7;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#B4C6E7;border-right-style:solid;border-right-width:1pt;border-right-color:#B4C6E7">
                <p class="s10" style="padding-left: 5pt;text-indent: 0pt;line-height: 119%;text-align: left;">Toutes
                    Données que vous choisissez de nous transmettre via L’Application</p>
                <p class="s10" style="padding-left: 5pt;text-indent: 0pt;line-height: 12pt;text-align: left;">Adresse IP
                </p>
            </td>
            <td
                style="width:55pt;border-top-style:solid;border-top-width:1pt;border-top-color:#B4C6E7;border-left-style:solid;border-left-width:1pt;border-left-color:#B4C6E7;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#B4C6E7;border-right-style:solid;border-right-width:1pt;border-right-color:#B4C6E7">
                <p class="s10"
                    style="padding-left: 5pt;padding-right: 5pt;text-indent: 0pt;line-height: 119%;text-align: left;">
                    Exécution du contrat Consente</p>
                <p class="s10" style="padding-left: 5pt;text-indent: 0pt;line-height: 12pt;text-align: left;">ment</p>
            </td>
            <td
                style="width:164pt;border-top-style:solid;border-top-width:1pt;border-top-color:#B4C6E7;border-left-style:solid;border-left-width:1pt;border-left-color:#B4C6E7;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#B4C6E7;border-right-style:solid;border-right-width:1pt;border-right-color:#B4C6E7">
                <p class="s10"
                    style="padding-left: 5pt;padding-right: 4pt;text-indent: 0pt;line-height: 119%;text-align: justify;">
                    Jusqu’à suppression de votre compte utilisateur ou desdites Données à votre initiative uniquement
                </p>
            </td>
        </tr>
        <tr style="height:55pt">
            <td
                style="width:36pt;border-top-style:solid;border-top-width:1pt;border-top-color:#B4C6E7;border-left-style:solid;border-left-width:1pt;border-left-color:#B4C6E7;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#B4C6E7;border-right-style:solid;border-right-width:1pt;border-right-color:#B4C6E7">
                <p class="s9" style="padding-top: 1pt;text-indent: 0pt;text-align: center;">5</p>
            </td>
            <td
                style="width:164pt;border-top-style:solid;border-top-width:1pt;border-top-color:#B4C6E7;border-left-style:solid;border-left-width:1pt;border-left-color:#B4C6E7;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#B4C6E7;border-right-style:solid;border-right-width:1pt;border-right-color:#B4C6E7">
                <p class="s10"
                    style="padding-left: 5pt;padding-right: 4pt;text-indent: 0pt;line-height: 118%;text-align: justify;">
                    Exécution des fonctions de gestion d’évènements et de http en fonction des actions effectuées</p>
                <p class="s10" style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">par l’utilisateur</p>
            </td>
            <td
                style="width:105pt;border-top-style:solid;border-top-width:1pt;border-top-color:#B4C6E7;border-left-style:solid;border-left-width:1pt;border-left-color:#B4C6E7;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#B4C6E7;border-right-style:solid;border-right-width:1pt;border-right-color:#B4C6E7">
                <p class="s10" style="padding-left: 5pt;text-indent: 0pt;text-align: left;">Adresse IP</p>
            </td>
            <td
                style="width:55pt;border-top-style:solid;border-top-width:1pt;border-top-color:#B4C6E7;border-left-style:solid;border-left-width:1pt;border-left-color:#B4C6E7;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#B4C6E7;border-right-style:solid;border-right-width:1pt;border-right-color:#B4C6E7">
                <p class="s10"
                    style="padding-left: 5pt;padding-right: 5pt;text-indent: 0pt;line-height: 118%;text-align: left;">
                    Exécution du contrat</p>
            </td>
            <td
                style="width:164pt;border-top-style:solid;border-top-width:1pt;border-top-color:#B4C6E7;border-left-style:solid;border-left-width:1pt;border-left-color:#B4C6E7;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#B4C6E7;border-right-style:solid;border-right-width:1pt;border-right-color:#B4C6E7">
                <p class="s10"
                    style="padding-left: 5pt;padding-right: 4pt;text-indent: 0pt;line-height: 118%;text-align: justify;">
                    Traitement temporaire, uniquement dans le but de fournir et permettre le Service</p>
            </td>
        </tr>
    </table>
    <p style="text-indent: 0pt;text-align: left;"><br /></p>
    <p style="padding-top: 5pt;padding-left: 34pt;text-indent: 0pt;line-height: 119%;text-align: left;">À l’issue de la
        période de conservation, toutes Données vous concernant seront détruites ou anonymisées.</p>
    <p style="text-indent: 0pt;text-align: left;"><br /></p>
    <p class="s3" style="padding-top: 11pt;padding-left: 34pt;text-indent: 0pt;text-align: left;">ARTICLE 4 - VOS DROITS
    </p>
    <p style="text-indent: 0pt;text-align: left;"><br /></p>
    <p style="padding-top: 5pt;padding-left: 34pt;text-indent: 0pt;line-height: 119%;text-align: justify;">En
        application du Règlement Général sur la Protection des données et de la loi Informatique et Libertés du 6
        janvier 1978, vous disposez des droits ci-après mentionnés :</p>
    <p style="text-indent: 0pt;text-align: left;"><br /></p>
    <ul id="l6">
        <li data-list-text="-">
            <p class="s1" style="padding-left: 70pt;text-indent: -18pt;line-height: 111%;text-align: justify;">Un droit
                d’accès<span class="s11">, vous permettant de prendre connaissance des Données qui vous concernent et
                    qui ont fait l’objet d’un traitement ainsi que de la finalité de celui-ci;</span></p>
            <p style="text-indent: 0pt;text-align: left;"><br /></p>
        </li>
        <li data-list-text="-">
            <p class="s1" style="padding-left: 70pt;text-indent: -18pt;line-height: 115%;text-align: justify;">Un droit
                d’opposition et de limitation au traitement et à la prospection <span class="s11">vous permettant de
                    vous opposer au traitement et à l’utilisation de vos Données ou de demander le « gel » du traitement
                    de celles-ci, sauf s’il existe un motif impérieux prévalant sur vos intérêts ;</span></p>
            <p style="text-indent: 0pt;text-align: left;"><br /></p>
        </li>
        <li data-list-text="-">
            <p class="s1" style="padding-left: 70pt;text-indent: -18pt;line-height: 117%;text-align: justify;">Un droit
                de rectification et d’effacement <span class="s11">des Données vous concernant et apparaissant comme
                    étant inexactes, périmées ou incomplètes ou dont le traitement est interdit. Vous pourrez,
                    notamment, mettre ce droit en œuvre lorsque vos Données ont été traitées lorsque vous étiez une
                    personne mineure ;</span></p>
            <p style="text-indent: 0pt;text-align: left;"><br /></p>
        </li>
        <li data-list-text="-">
            <p class="s1" style="padding-left: 70pt;text-indent: -18pt;line-height: 114%;text-align: justify;">Un droit
                à la portabilité et à la récupération <span class="s11">des Données vous permettant de recevoir vos
                    Données traitées dans un format structuré et une forme aisément réutilisable, couramment utilisé et
                    lisible par machine, notamment dans le but de les transférer vers un autre service.</span></p>
        </li>
    </ul>
    <p style="text-indent: 0pt;text-align: left;"><br /></p>
    <p style="padding-left: 34pt;text-indent: 0pt;text-align: justify;">Vous pouvez exercer ces droits de plusieurs
        façons :</p>
    <p style="text-indent: 0pt;text-align: left;"><br /></p>
    <ul id="l7">
        <li data-list-text="&gt;">
            <p style="padding-left: 70pt;text-indent: -18pt;text-align: left;"><a href="mailto:contact@hellotag.fr"
                    class="s2" target="_blank">Soit en adressant un courriel à </a><a href="mailto:contact@hellotag.fr"
                    target="_blank">contact@hellotag.fr</a></p>
        </li>
        <li data-list-text="&gt;">
            <p style="padding-top: 1pt;padding-left: 70pt;text-indent: -18pt;line-height: 112%;text-align: justify;">
                Soit en adressant un courrier à l’adresse suivante : HELLOTAG CORPORATION, 19 rue du musée 13001
                MARSEILLE.</p>
        </li>
    </ul>
    <p style="text-indent: 0pt;text-align: left;"><br /></p>
    <p style="padding-left: 34pt;text-indent: 0pt;line-height: 118%;text-align: justify;">Si vous exercez l’un de ces
        droits par voie électronique, les Données vous seront fournies par voie électronique lorsque cela est possible,
        sauf si vous manifestez expressément votre choix qu’il en soit autrement lors de votre demande. Lorsque vous
        choisirez d’exercer vos droits par voie postale, vous devrez nous communiquer l’adresse à laquelle doit vous
        parvenir notre réponse.</p>
    <p style="padding-top: 4pt;padding-left: 34pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Votre demande
        devra également être signée et accompagnée d’un justificatif d’identité, lequel ne devrait pas être conservé par
        nos soins, sauf en cas de besoin de conservation d’une preuve, et ce, en noir et blanc, en faible définition, et
        sous forme d’un fichier chiffré lorsque la demande est effectuée par voie numérique. Ces justificatifs
        d’identité pourront alors être conservés pendant un délai d’un an, délai qui sera porté à trois ans en cas
        d’exercice du droit d’opposition.</p>
    <p style="text-indent: 0pt;text-align: left;"><br /></p>
    <p style="padding-left: 34pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Dès réception de votre
        demande, HELLOTAG dispose d’un délai d’un mois afin de vous répondre. Ce délai pourra exceptionnellement être
        prolongé de deux mois supplémentaires en cas de demande dite</p>
    <p style="padding-left: 34pt;text-indent: 0pt;line-height: 119%;text-align: justify;">« complexe » ou en cas de
        saturation des demandes. Dans ce cas, HELLOTAG CORPORATION informera l’Utilisateur de la prolongation du délai
        de réponse.</p>
    <p style="text-indent: 0pt;text-align: left;"><br /></p>
    <p style="padding-left: 34pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Néanmoins, si la demande est
        manifestement abusive notamment par son caractère répétitif ou systématique ou que cette demande est émise par
        une autre personne que la personne concernée, laquelle ne dispose pas de mandat, ou que les Données visées ne
        sont pas conservées par HELLOTAG, l’Utilisateur sera informé du refus d’accéder à sa demande.</p>
    <p style="text-indent: 0pt;text-align: left;"><br /></p>
    <p style="padding-left: 34pt;text-indent: 0pt;line-height: 118%;text-align: justify;">L’Utilisateur reconnaît qu’en
        cas de suppression de son compte et/ou de l’un de ses Profils sur l’Application et /ou sur le site
        my-hellotag.fr. et dans l’hypothèse où il exercerait son droit d’effacement, les Données constituant ses Profils
        seront définitivement supprimées. Ainsi, s’il entendait utiliser à nouveau les Services, il lui appartiendra de
        re-créer un Profil et de les Fiches sociales et professionnelles.</p>
    <p style="text-indent: 0pt;text-align: left;"><br /></p>
    <p class="s3" style="padding-top: 11pt;padding-left: 34pt;text-indent: 0pt;text-align: justify;">ARTICLE 5 -
        SÉCURITÉ DES DONNÉES/CONFIDENTIALITÉ</p>
    <p style="text-indent: 0pt;text-align: left;"><br /></p>
    <p style="padding-top: 5pt;padding-left: 34pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Des mesures
        de sécurité physiques, logiques, techniques et organisationnelles appropriées sont prévues afin de garantir la
        confidentialité des Données, et notamment aux fins d’éviter qu’elles soient déformées, endommagées, divulguées
        et/ou que des tiers non-autorisés y aient accès.</p>
    <p style="text-indent: 0pt;text-align: left;"><br /></p>
    <p style="padding-left: 34pt;text-indent: 0pt;line-height: 118%;text-align: justify;">HELLOTAG CORPORATION s’engage
        à utiliser tout procédé adéquat et nécessaires à la préservation des Données, et notamment à adopter des process
        interne permettant d’assurer une sécurité accrue des Données des Utilisateurs, notamment si les circonstances
        l’exigent, de procéder à toute analyse d’impact préalablement à tout traitement qui serait susceptible
        d’entrainer un risque élevé pour les droits et libertés de ses Utilisateurs.</p>
    <p style="text-indent: 0pt;text-align: left;"><br /></p>
    <p class="s3" style="padding-left: 34pt;text-indent: 0pt;text-align: justify;">Sécurité de l’Application HELLOTAG
    </p>
    <p style="padding-top: 2pt;padding-left: 34pt;text-indent: 0pt;line-height: 119%;text-align: justify;">HELLOTAG a
        choisi de confier à un professionnel reconnu dans le secteur de la sous-traitance informatique et de la
        cyber-sécurité, lequel fourni l’interface technique permettant la collecte des Données auprès des Utilisateurs :
        Google Firebase.</p>
    <p style="text-indent: 0pt;text-align: left;"><br /></p>
    <p style="padding-left: 34pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Google Firebase assure
        également le stockage et l’hébergement de l’Application et des Données traitées dans le cadre du Service pour le
        compte d’HELLOTAG.</p>
    <p style="text-indent: 0pt;text-align: left;"><br /></p>
    <p style="padding-left: 34pt;text-indent: 0pt;line-height: 118%;text-align: justify;">En qualité de sous-traitant,
        Google Firebase est tenue au respect de la Loi et du règlement et notamment, au respect de la confidentialité,
        de l’intégrité et de la sécurité de l’ensemble de vos Données. Ce partenaire a notamment été choisi par nos
        soins pour ses normes de confidentialité et de sécurité exigeantes, notamment en termes de chiffrement de vos
        données stockées, et n’est habilité à utiliser les Données que dans le cadre de l’exécution de sa mission de
        prestation de service.</p>
    <p style="text-indent: 0pt;text-align: left;"><br /></p>
    <p style="padding-left: 34pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Google Firebase étant amené à
        traiter vos données personnelles, nous vous invitons à consulter attentivement leur <span class="s12">politique
            de confidentialité</span>. Dans ce cadre, HELLOTAGG s’est assurée que son</p>
    <p style="padding-top: 4pt;padding-left: 34pt;text-indent: 0pt;line-height: 119%;text-align: left;">sous-traitant
        apporte les meilleures garanties de sécurité – au regard du risque envisagé et de la typologie de données
        traitées – tout en permettant une utilisation fluide et fidèle de l’Application.</p>
    <p style="text-indent: 0pt;text-align: left;"><br /></p>
    <p style="padding-left: 34pt;text-indent: 0pt;line-height: 119%;text-align: left;">HELLOTAG, en sa qualité de
        bénéficiaire du traitement, a pour sa part mis en place des mesures de sécurité renforcées dans le but de
        sécuriser vos Données :</p>
    <p style="text-indent: 0pt;text-align: left;"><br /></p>
    <ul id="l8">
        <li data-list-text="">
            <p style="padding-left: 70pt;text-indent: -18pt;line-height: 117%;text-align: justify;">L’accès à vos
                Données est contrôlé et strictement restreint à certains employés qui ont une habilitation spécifique et
                un objectif professionnel précis à cet accès ;</p>
        </li>
        <li data-list-text="">
            <p style="padding-left: 70pt;text-indent: -18pt;line-height: 12pt;text-align: justify;">L’accès à vos
                Données est sécurisé par un protocole d’authentification préalable ;</p>
        </li>
        <li data-list-text="">
            <p style="padding-top: 1pt;padding-left: 70pt;text-indent: -18pt;line-height: 118%;text-align: justify;">
                Toutes les Données que vous avez choisi de protéger par un mot de passe de sécurité sont chiffrés et
                parfaitement illisibles, même pour l’équipe HELLOTAG. Nous vous recommandons dans ce cadre d’activer
                cette fonctionnalité au sein de votre Application ;</p>
        </li>
        <li data-list-text="">
            <p style="padding-left: 70pt;text-indent: -18pt;line-height: 12pt;text-align: justify;">Nos serveurs font
                l’objet d’une protection renforcée.</p>
        </li>
    </ul>
    <p style="text-indent: 0pt;text-align: left;"><br /></p>
    <p class="s3" style="padding-left: 34pt;text-indent: 0pt;text-align: left;">ARTICLE 6 - COOKIES</p>
    <p style="text-indent: 0pt;text-align: left;"><br /></p>
    <p style="padding-top: 5pt;padding-left: 34pt;text-indent: 0pt;line-height: 118%;text-align: justify;">Conformément
        à la délibération de la CNIL n° 2020-092 du 17 septembre 2020 portant adoption d’une recommandation proposant
        des modalités pratiques de mise en conformité en cas de recours aux « cookies et autres traceurs », nous vous
        informons que des cookies enregistrent certaines informations qui sont stockées dans la mémoire de votre
        équipement informatique.</p>
    <p style="text-indent: 0pt;text-align: left;"><br /></p>
    <p style="padding-left: 34pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Un « cookie » est une suite
        d’informations enregistrée sous forme de fichier sur le disque dur de votre terminal (ordinateur, téléphone,
        tablette) à la demande du serveur du site ou de l’application visitée afin de lui transmettre certaines
        informations. Votre terminal le conservera pendant une certaine durée, et le renverra au serveur web chaque fois
        que vous vous connecterez au site ou à l’application.</p>
    <p style="text-indent: 0pt;text-align: left;"><br /></p>
    <p style="padding-left: 34pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Les cookies sont utilisés afin
        de faciliter la navigation sur un site ou une application notamment aux fins d’en faciliter la navigation, à des
        fins de traçage, à des fins statistiques ou d’études de données publicitaires, etc.</p>
    <p style="text-indent: 0pt;text-align: left;"><br /></p>
    <p class="s1" style="padding-left: 34pt;text-indent: 0pt;text-align: left;">6.1. Typologie des cookies déposés au
        sein de l’Application et du Site</p>
    <p style="text-indent: 0pt;text-align: left;"><br /></p>
    <p class="s1" style="padding-left: 69pt;text-indent: 0pt;text-align: left;">- Cookies fonctionnels et nécessaires au
        fonctionnement du Site et de l’Application</p>
    <p style="text-indent: 0pt;text-align: left;"><br /></p>
    <p style="padding-left: 34pt;text-indent: 0pt;line-height: 119%;text-align: justify;">HELLOTAG est susceptibles de
        déposer un ou plusieurs cookies au sein de my-hellotag.fr, du site hellotag.fr et de l’Application dans la
        mesure strictement nécessaire à la fourniture et au fonctionnement optimal du Service, et ayant pour unique
        finalité de permettre ou faciliter la communication par voie électronique. Il s’agit notamment des cookies de
        personnalisation de votre interface ou encore des cookies d’identification de session et d’authentification.</p>
    <p style="text-indent: 0pt;text-align: left;"><br /></p>
    <p class="s12" style="padding-left: 34pt;text-indent: 0pt;line-height: 118%;text-align: justify;"><a
            href="mailto:contact@hellotag.fr" class="s2" target="_blank">Ces cookies fonctionnels étant nécessaire au
            bon fonctionnement du Service, ils ne nécessitent pas votre consentement préalable, vous pouvez néanmoins
            vous opposer à leur dépôt auprès d’HELLOTAG, en adressant un mail à l’adresse </a>contact@hellotag.fr<span
            class="s6"> </span><span class="p">ou, pour ce qui concerne le site My-hellotag.fr, au moyen du formulaire
            cookies mis à votre disposition.</span></p>
    <p style="text-indent: 0pt;text-align: left;"><br /></p>
    <p class="s1" style="padding-left: 34pt;text-indent: 0pt;line-height: 125%;text-align: justify;">En tout état de
        cause, l’Utilisateur est informé que dans l’hypothèse où il souhaiterait refuser le recours aux cookies
        fonctionnels, l’Application ne pourra fonctionner correctement.</p>
    <p class="s1" style="padding-top: 5pt;padding-left: 69pt;text-indent: 0pt;text-align: left;">- Cookies de mesure
        d’audience au sein de l’Application</p>
    <p style="text-indent: 0pt;text-align: left;"><br /></p>
    <p class="s12" style="padding-left: 34pt;text-indent: 0pt;line-height: 119%;text-align: justify;"><span
            class="p">Dans le but de nous permettre d’améliorer et de développer les fonctionnalités de l’Application et
            du Service, nous sommes susceptibles de procéder, à des fins statistiques, à des mesures d’audience et de
            fréquentation grâce à des cookies, et ce notamment au travers des consultations du site
        </span>www.my-hellotag.fr<span class="p">.</span></p>
    <p style="text-indent: 0pt;text-align: left;"><br /></p>
    <p style="padding-left: 34pt;text-indent: 0pt;line-height: 118%;text-align: justify;">Les Données collectées
        permettent uniquement de nous fournir des données statistiques anonymes d’audience et de fréquentation (le
        nombre de visites sur un profil, le nombre de profil consultés, le nombre de consultations de réseaux
        sociaux..). À toute fin utile, il est précisé que l’anonymisation d’une donnée fait perdre le caractère
        identifiant de celle-ci de manière irréversible.</p>
    <p style="text-indent: 0pt;text-align: left;"><br /></p>
    <p class="s8" style="padding-top: 11pt;padding-left: 84pt;text-indent: 0pt;text-align: center;">TABLEAU DES COOKIES
        DÉPOSÉS PAR HELLOTAG</p>
    <p style="text-indent: 0pt;text-align: left;"><br /></p>
    <p style="text-indent: 0pt;text-align: left;"><br /></p>
    <table style="border-collapse:collapse;margin-left:20.1678pt" cellspacing="0">
        <tr style="height:28pt">
            <td
                style="width:27pt;border-top-style:solid;border-top-width:1pt;border-top-color:#B4C6E7;border-left-style:solid;border-left-width:1pt;border-left-color:#B4C6E7;border-bottom-style:solid;border-bottom-width:2pt;border-bottom-color:#8EAADB;border-right-style:solid;border-right-width:1pt;border-right-color:#B4C6E7">
                <p class="s9"
                    style="padding-top: 1pt;padding-left: 7pt;padding-right: 6pt;text-indent: 0pt;text-align: center;">
                    N°</p>
            </td>
            <td
                style="width:164pt;border-top-style:solid;border-top-width:1pt;border-top-color:#B4C6E7;border-left-style:solid;border-left-width:1pt;border-left-color:#B4C6E7;border-bottom-style:solid;border-bottom-width:2pt;border-bottom-color:#8EAADB;border-right-style:solid;border-right-width:1pt;border-right-color:#B4C6E7">
                <p class="s9" style="padding-top: 1pt;padding-left: 7pt;text-indent: 0pt;text-align: left;">
                    Traitement/Finalité du traitement</p>
            </td>
            <td
                style="width:89pt;border-top-style:solid;border-top-width:1pt;border-top-color:#B4C6E7;border-left-style:solid;border-left-width:1pt;border-left-color:#B4C6E7;border-bottom-style:solid;border-bottom-width:2pt;border-bottom-color:#8EAADB;border-right-style:solid;border-right-width:1pt;border-right-color:#B4C6E7">
                <p class="s9" style="padding-top: 1pt;padding-left: 24pt;text-indent: 0pt;text-align: left;">Données</p>
                <p class="s9" style="padding-top: 2pt;padding-left: 22pt;text-indent: 0pt;text-align: left;">collectées
                </p>
            </td>
            <td
                style="width:105pt;border-top-style:solid;border-top-width:1pt;border-top-color:#B4C6E7;border-left-style:solid;border-left-width:1pt;border-left-color:#B4C6E7;border-bottom-style:solid;border-bottom-width:2pt;border-bottom-color:#8EAADB;border-right-style:solid;border-right-width:1pt;border-right-color:#B4C6E7">
                <p class="s9"
                    style="padding-top: 1pt;padding-left: 3pt;padding-right: 2pt;text-indent: 0pt;text-align: center;">
                    Base légale</p>
            </td>
            <td
                style="width:104pt;border-top-style:solid;border-top-width:1pt;border-top-color:#B4C6E7;border-left-style:solid;border-left-width:1pt;border-left-color:#B4C6E7;border-bottom-style:solid;border-bottom-width:2pt;border-bottom-color:#8EAADB;border-right-style:solid;border-right-width:1pt;border-right-color:#B4C6E7">
                <p class="s9"
                    style="padding-top: 1pt;padding-left: 23pt;padding-right: 22pt;text-indent: 0pt;text-align: center;">
                    Durée de</p>
                <p class="s9"
                    style="padding-top: 2pt;padding-left: 23pt;padding-right: 22pt;text-indent: 0pt;text-align: center;">
                    conservation</p>
            </td>
        </tr>
        <tr style="height:14pt">
            <td
                style="width:27pt;border-top-style:solid;border-top-width:2pt;border-top-color:#8EAADB;border-left-style:solid;border-left-width:1pt;border-left-color:#B4C6E7;border-right-style:solid;border-right-width:1pt;border-right-color:#B4C6E7">
                <p class="s13" style="text-indent: 0pt;text-align: center;">1</p>
            </td>
            <td
                style="width:164pt;border-top-style:solid;border-top-width:2pt;border-top-color:#8EAADB;border-left-style:solid;border-left-width:1pt;border-left-color:#B4C6E7;border-right-style:solid;border-right-width:1pt;border-right-color:#B4C6E7">
                <p class="s10" style="padding-left: 5pt;text-indent: 0pt;text-align: left;">Cookies fonctionnels</p>
            </td>
            <td
                style="width:89pt;border-top-style:solid;border-top-width:2pt;border-top-color:#8EAADB;border-left-style:solid;border-left-width:1pt;border-left-color:#B4C6E7;border-right-style:solid;border-right-width:1pt;border-right-color:#B4C6E7">
                <p class="s10" style="padding-left: 4pt;padding-right: 4pt;text-indent: 0pt;text-align: center;">Données
                </p>
            </td>
            <td
                style="width:105pt;border-top-style:solid;border-top-width:2pt;border-top-color:#8EAADB;border-left-style:solid;border-left-width:1pt;border-left-color:#B4C6E7;border-right-style:solid;border-right-width:1pt;border-right-color:#B4C6E7">
                <p class="s10" style="padding-left: 3pt;padding-right: 4pt;text-indent: 0pt;text-align: center;">
                    Exécution du contrat</p>
            </td>
            <td
                style="width:104pt;border-top-style:solid;border-top-width:2pt;border-top-color:#8EAADB;border-left-style:solid;border-left-width:1pt;border-left-color:#B4C6E7;border-right-style:solid;border-right-width:1pt;border-right-color:#B4C6E7">
                <p class="s10" style="padding-left: 5pt;text-indent: 0pt;text-align: left;">13 mois à</p>
            </td>
        </tr>
        <tr style="height:14pt">
            <td
                style="width:27pt;border-left-style:solid;border-left-width:1pt;border-left-color:#B4C6E7;border-right-style:solid;border-right-width:1pt;border-right-color:#B4C6E7">
                <p style="text-indent: 0pt;text-align: left;"><br /></p>
            </td>
            <td
                style="width:164pt;border-left-style:solid;border-left-width:1pt;border-left-color:#B4C6E7;border-right-style:solid;border-right-width:1pt;border-right-color:#B4C6E7">
                <p style="text-indent: 0pt;text-align: left;"><br /></p>
            </td>
            <td
                style="width:89pt;border-left-style:solid;border-left-width:1pt;border-left-color:#B4C6E7;border-right-style:solid;border-right-width:1pt;border-right-color:#B4C6E7">
                <p class="s10" style="padding-left: 4pt;padding-right: 4pt;text-indent: 0pt;text-align: center;">
                    d’authentification</p>
            </td>
            <td
                style="width:105pt;border-left-style:solid;border-left-width:1pt;border-left-color:#B4C6E7;border-right-style:solid;border-right-width:1pt;border-right-color:#B4C6E7">
                <p style="text-indent: 0pt;text-align: left;"><br /></p>
            </td>
            <td
                style="width:104pt;border-left-style:solid;border-left-width:1pt;border-left-color:#B4C6E7;border-right-style:solid;border-right-width:1pt;border-right-color:#B4C6E7">
                <p class="s10" style="padding-left: 5pt;text-indent: 0pt;text-align: left;">l’expiration de</p>
            </td>
        </tr>
        <tr style="height:14pt">
            <td
                style="width:27pt;border-left-style:solid;border-left-width:1pt;border-left-color:#B4C6E7;border-right-style:solid;border-right-width:1pt;border-right-color:#B4C6E7">
                <p style="text-indent: 0pt;text-align: left;"><br /></p>
            </td>
            <td
                style="width:164pt;border-left-style:solid;border-left-width:1pt;border-left-color:#B4C6E7;border-right-style:solid;border-right-width:1pt;border-right-color:#B4C6E7">
                <p style="text-indent: 0pt;text-align: left;"><br /></p>
            </td>
            <td
                style="width:89pt;border-left-style:solid;border-left-width:1pt;border-left-color:#B4C6E7;border-right-style:solid;border-right-width:1pt;border-right-color:#B4C6E7">
                <p class="s10" style="padding-left: 4pt;padding-right: 4pt;text-indent: 0pt;text-align: center;">et de
                    session,</p>
            </td>
            <td
                style="width:105pt;border-left-style:solid;border-left-width:1pt;border-left-color:#B4C6E7;border-right-style:solid;border-right-width:1pt;border-right-color:#B4C6E7">
                <p style="text-indent: 0pt;text-align: left;"><br /></p>
            </td>
            <td
                style="width:104pt;border-left-style:solid;border-left-width:1pt;border-left-color:#B4C6E7;border-right-style:solid;border-right-width:1pt;border-right-color:#B4C6E7">
                <p class="s10" style="padding-left: 5pt;text-indent: 0pt;text-align: left;">laquelle votre</p>
            </td>
        </tr>
        <tr style="height:14pt">
            <td
                style="width:27pt;border-left-style:solid;border-left-width:1pt;border-left-color:#B4C6E7;border-right-style:solid;border-right-width:1pt;border-right-color:#B4C6E7">
                <p style="text-indent: 0pt;text-align: left;"><br /></p>
            </td>
            <td
                style="width:164pt;border-left-style:solid;border-left-width:1pt;border-left-color:#B4C6E7;border-right-style:solid;border-right-width:1pt;border-right-color:#B4C6E7">
                <p style="text-indent: 0pt;text-align: left;"><br /></p>
            </td>
            <td
                style="width:89pt;border-left-style:solid;border-left-width:1pt;border-left-color:#B4C6E7;border-right-style:solid;border-right-width:1pt;border-right-color:#B4C6E7">
                <p class="s10" style="padding-left: 4pt;padding-right: 4pt;text-indent: 0pt;text-align: center;">
                    Adresses IP,</p>
            </td>
            <td
                style="width:105pt;border-left-style:solid;border-left-width:1pt;border-left-color:#B4C6E7;border-right-style:solid;border-right-width:1pt;border-right-color:#B4C6E7">
                <p style="text-indent: 0pt;text-align: left;"><br /></p>
            </td>
            <td
                style="width:104pt;border-left-style:solid;border-left-width:1pt;border-left-color:#B4C6E7;border-right-style:solid;border-right-width:1pt;border-right-color:#B4C6E7">
                <p class="s10" style="padding-left: 5pt;text-indent: 0pt;text-align: left;">consentement sera</p>
            </td>
        </tr>
        <tr style="height:28pt">
            <td
                style="width:27pt;border-left-style:solid;border-left-width:1pt;border-left-color:#B4C6E7;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#B4C6E7;border-right-style:solid;border-right-width:1pt;border-right-color:#B4C6E7">
                <p style="text-indent: 0pt;text-align: left;"><br /></p>
            </td>
            <td
                style="width:164pt;border-left-style:solid;border-left-width:1pt;border-left-color:#B4C6E7;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#B4C6E7;border-right-style:solid;border-right-width:1pt;border-right-color:#B4C6E7">
                <p style="text-indent: 0pt;text-align: left;"><br /></p>
            </td>
            <td
                style="width:89pt;border-left-style:solid;border-left-width:1pt;border-left-color:#B4C6E7;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#B4C6E7;border-right-style:solid;border-right-width:1pt;border-right-color:#B4C6E7">
                <p class="s10" style="padding-left: 4pt;padding-right: 4pt;text-indent: 0pt;text-align: center;">
                    Préférences</p>
                <p class="s10"
                    style="padding-top: 2pt;padding-left: 4pt;padding-right: 4pt;text-indent: 0pt;text-align: center;">
                    système</p>
            </td>
            <td
                style="width:105pt;border-left-style:solid;border-left-width:1pt;border-left-color:#B4C6E7;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#B4C6E7;border-right-style:solid;border-right-width:1pt;border-right-color:#B4C6E7">
                <p style="text-indent: 0pt;text-align: left;"><br /></p>
            </td>
            <td
                style="width:104pt;border-left-style:solid;border-left-width:1pt;border-left-color:#B4C6E7;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#B4C6E7;border-right-style:solid;border-right-width:1pt;border-right-color:#B4C6E7">
                <p class="s10" style="padding-left: 5pt;text-indent: 0pt;text-align: left;">de nouveau requis</p>
            </td>
        </tr>
    </table>
    <p style="text-indent: 0pt;text-align: left;"><br /></p>
    <p class="s1" style="padding-top: 5pt;padding-left: 34pt;text-indent: 0pt;text-align: left;">6.2 Moyens d’opposition
        au dépôt de cookies</p>
    <p style="text-indent: 0pt;text-align: left;"><br /></p>
    <p style="padding-left: 34pt;text-indent: 0pt;line-height: 119%;text-align: justify;">Vous pouvez, à tout moment,
        choisir de désactiver l’ensemble des cookies déposés au sein du Site et/ou de l’Application. Cependant, nous
        vous informons que votre expérience utilisateur risque d’en être dégradée.</p>
    <p style="text-indent: 0pt;text-align: left;"><br /></p>
    <p class="s7" style="padding-left: 34pt;text-indent: 0pt;line-height: 119%;text-align: justify;">S’agissant de
        l’Application<span class="p">, vous pouvez désactiver le dépôt de cookies en paramétrant l’outil de gestion des
            cookies disponible au sein de l’Application. Etant précisé que s’agissant uniquement de cookies
            fonctionnels, leur désactivation entrainera une dégradation de l’expérience utilisateur.</span></p>
    <p style="text-indent: 0pt;text-align: left;"><br /></p>
    <p class="s7" style="padding-left: 34pt;text-indent: 0pt;line-height: 117%;text-align: justify;">S’agissant de
        my-hellotag.fr,<span class="p"> vous pouvez à tout moment recourir à l’outils « gérer ma vie privée » et bloquer
            tout cookies à partir des options de votre navigateur :</span></p>
    <p style="text-indent: 0pt;text-align: left;"><br /></p>
    <ul id="l9">
        <li data-list-text="-">
            <p class="s14" style="padding-left: 48pt;text-indent: -14pt;text-align: left;">Firefox</p>
        </li>
        <li data-list-text="-">
            <p class="s14" style="padding-top: 1pt;padding-left: 48pt;text-indent: -14pt;text-align: left;">Chrome</p>
        </li>
        <li data-list-text="-">
            <p class="s14" style="padding-top: 1pt;padding-left: 48pt;text-indent: -14pt;text-align: left;">Explorer</p>
        </li>
        <li data-list-text="-">
            <p class="s14" style="padding-top: 1pt;padding-left: 48pt;text-indent: -14pt;text-align: left;">Safari</p>
        </li>
        <li data-list-text="-">
            <p class="s14" style="padding-top: 1pt;padding-left: 48pt;text-indent: -14pt;text-align: left;">Opera</p>
        </li>
    </ul>
    <p style="text-indent: 0pt;text-align: left;"><br /></p>
    <p class="s3" style="padding-top: 6pt;padding-left: 34pt;text-indent: 0pt;text-align: justify;">ARTICLE 7 -
        MODIFICIATIONS DE LA POLITIQUE DE CONFIDENTIALITÉ</p>
    <p style="text-indent: 0pt;text-align: left;"><br /></p>
    <p style="padding-left: 34pt;text-indent: 0pt;line-height: 119%;text-align: justify;">HELLOTAG pourra modifier le
        contenu de la présente politique de données personnelles à tout moment et de manière régulière suivant
        l’évolution de ses activités et le développement du Service qui vous est proposé. Si nous apportons des
        modifications matérielles à la présente, nous vous le</p>
    <p style="padding-top: 4pt;padding-left: 34pt;text-indent: 0pt;line-height: 119%;text-align: left;">notifierons par
        le biais du Service ou par tout autre moyen. Vous serez ainsi libre d’exercer vos droits et/ou d’interrompre le
        contrat si lesdites modifications ne vous conviennent pas.</p>
    <p style="text-indent: 0pt;text-align: left;"><br /></p>
    <p style="padding-left: 34pt;text-indent: 0pt;line-height: 119%;text-align: left;"><a href="http://www.hellotag.fr/"
            class="s2" target="_blank">La présente Politique, dans sa version en vigueur, vous est accessible à tout
            moment sur le site www.hellotag.fr.</a></p>

