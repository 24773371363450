import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { map, switchMap, take } from 'rxjs/operators';
import { Fic } from '../model/fic';
import firebase from "firebase/app";

const isEmptyObj = (o: any) => Object.keys(o).length == 0;

@Injectable({
  providedIn: 'root'
})
export class ProfilesService {

  constructor(
    private firestore: AngularFirestore) {
  }  

  getFile(profilId: string) {
    return this.firestore.collection('pro_files', ref => ref.where('profilId', '==', profilId))
      .snapshotChanges()
      .pipe(map(r =>
        r.map(rel => {
          const data = rel.payload.doc.data() as Fic;
          data.id = rel.payload.doc.id;
          return data;
        })
      ));
  }

  getFile2(profilId: string) {
    return this.firestore.collection('pro_files', ref => ref.where(firebase.firestore.FieldPath.documentId(), '==', "vCvuAwzZNM27TY7Wgddk"))
      .snapshotChanges()
      .pipe(map(r =>
        r.map(rel => {
          const data = rel.payload.doc.data() as Fic;
          data.id = rel.payload.doc.id;
          return data;
        })
      ));
  }

  getFileByUserId(userId: string) {
    return this.firestore.collection('pro_files', ref => ref.where('userId', '==', userId))
      .snapshotChanges()
      .pipe(map(r =>
        r.map(rel => {
          const data = rel.payload.doc.data() as Fic;
          data.id = rel.payload.doc.id;
          return data;
        })
      ));
  }
  
}
