<header>
    <nav style="display: flex;">
        <div class="logo">
            <img src="assets/images/logo-white.svg" width="112px" />
        </div>
        <div class="menu">
            <ul>
                <li><a href="#">PROFIL PRO</a></li>
                <li><a href="#">PROFIL SOCIAL</a></li>
                <li><a href="#">DESACTIVER MON COMPTE</a></li>
                <li><a href="#">SIGNALER LE COMPTE</a></li>
            </ul>
        </div>
        <div *ngIf="p.id" (click)="toggled = !toggled" id="burger" class="burger" [style.background]="burgerColor" [class.toggled]="toggled">
            <svg viewBox="0 0 100 80" width="30" height="31" onclick="toggleMenu()">
                <rect width="100" height="10"></rect>
                <rect y="30" width="100" height="10"></rect>
                <rect y="60" width="100" height="10"></rect>
            </svg>
            <div id="menumob" class="menu-mobile">
                <ul>
                    <!-- <li *ngIf="p.pro_enabled"><a [routerLink]="['../pro',p.id]">PROFIL PRO</a></li>
                    <li *ngIf="p.social_enabled"><a [routerLink]="['../social',p.id]">PROFIL SOCIAL</a></li>
                    <li><a [routerLink]="['../desac','new']">DESACTIVER MON COMPTE</a></li>
                    <li><a [routerLink]="['../signaler']">SIGNALER LE COMPTE</a></li> -->

                    <li *ngIf="p.pro_enabled"><a [routerLink]="['../pro',p.id]">PROFIL PRO</a></li>
                    <li *ngIf="p.social_enabled"><a [routerLink]="['../social',p.id]">PROFIL SOCIAL</a></li>
                    <li *ngIf="p"><a [routerLink]="['../desac','new']">DESACTIVER MON COMPTE</a></li>
                    <li><a [routerLink]="['../signaler']">SIGNALER LE COMPTE</a></li>

                </ul>
            </div>
        </div>
    </nav>
</header>