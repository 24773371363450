import { Component, OnInit, OnDestroy } from '@angular/core';
import { Profil, ProProfile, NormSocial } from 'src/app/model/profil';
import { ProfilService } from 'src/app/services/profil.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { Social } from 'src/app/model/social';
import { Fichier } from 'src/app/model/fichier';
import { HttpClient } from '@angular/common/http';
import { take } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/services/user.service';
import { ToolsService } from 'src/app/services/tools.service';
import { ProFile } from 'src/app/model/proFile';
type KeepOneProperty<T, K extends keyof T> = Pick<T, K>;
type RemovePropertiesExceptOne<T, K extends keyof T> = Omit<T, Exclude<keyof T, K>>;

const buf2hex = (buffer: ArrayBuffer) =>
  [...new Uint8Array(buffer)]
    .map(x => x.toString(16).padStart(2, '0'))
    .join('');


@Component({
  selector: 'app-handler',
  templateUrl: './handler.component.html',
  styleUrls: ['./handler.component.css']
})
export class HandlerComponent implements OnInit, OnDestroy {

  p: Profil = new Profil();
  id;
  mail;
  mdp;
  connected;
  fic;
  elCodo;
  loaded = true;
  sub?: Subscription;
  creds;

  constructor(private http: HttpClient, private toolsService: ToolsService, private profilService: ProfilService, private userService: UserService, private firestore: AngularFirestore, private route: ActivatedRoute, private router: Router, public ngFireAuth: AngularFireAuth,
    // Inject Firebase auth service
  ) { }
  ngOnDestroy(): void {
    console.log("destroy");
    this.sub?.unsubscribe();
  }

  async genericAuth() {
    this.creds = await this.ngFireAuth.signInAnonymously();
    let u = await this.userService.getUser(this.creds.user.uid);

    if (!!u) {
      let x = this.toolsService.mergeDeep(u);
      await this.firestore.collection('users').doc(this.creds.user.uid).update(
        { ...x }
      );
    } else {
      debugger;
      await this.firestore.collection('users').doc(this.creds.user.uid).set(
        { ...u }
      );
    }

    // this.creds = await this.ngFireAuth.signInWithEmailAndPassword("testzoom1337@gmail.com", "GenericUser1337");
    return this.creds;
  }

  async ngOnInit() {
    let creds = await this.genericAuth();
    this.loaded = true;
    this.id = this.route.snapshot.params.id;
    if (this.id == null || this.id == undefined)
      window.location.href = "https://hellotag.fr"
    if (this.id != null && this.id != undefined && this.id != 'admin' && this.id != 'signaler') {
      this.sub = this.profilService.getProfilSub(this.id).subscribe(async p => {
        this.p = p;
        if(this.p['color'] != null || this.p['color'] != undefined  ) {
          let colorQuery = await this.firestore.collection('colors', ref => ref.where("couleur","==",this.p['color'])).get().toPromise();
          let color = colorQuery.docs[0].data() as any;       
          localStorage.setItem("color",JSON.stringify({...color}));
        } else {
          let colorQuery = await this.firestore.collection('colors', ref => ref.where("couleur","==","bleu")).get().toPromise();
          let color = colorQuery.docs[0].data() as any;      
 
          localStorage.setItem("color",JSON.stringify({...color}));
        }

        if (this.p.enable == false) {
          this.sub?.unsubscribe();
          this.loaded = false;
          this.router.navigate(['/desactiver', this.id]);
          return;
        } else if (this.p.findme) {
          this.sub?.unsubscribe();
          this.loaded = false;
          this.router.navigate(['/findme', this.id]);
          return;
        }
        else {

          if (this.p.social_enabled || this.p.pro_enabled) {

            if (this.p.pro_enabled && this.p.social_enabled) {
              this.sub?.unsubscribe();
              this.loaded = false;
              this.router.navigate(['/pro', this.id]);
              return;
            }
            if (this.p.pro_enabled && !this.p.social_enabled) {
              this.sub?.unsubscribe();
              this.loaded = false;
              this.router.navigate(['/pro', this.id]);
              return;
            }

            if (this.p.pro_enabled && this.p.social_enabled) {
              this.sub?.unsubscribe();
              this.loaded = false;
              this.router.navigate(['/pro', this.id]);
              return;
            }

            /////// PRO //////

            if (!this.p.pro_enabled && this.p.social_enabled) {
              this.sub?.unsubscribe();
              this.loaded = false;
              this.router.navigate(['/social', this.id]);
              return;
            }
          }
        }
      });


    } else if (this.id == 'admin') {
      this.loaded = false;
      this.router.navigate(['admin']);
    } else if (this.id == 'signaler') {
      this.loaded = false;
      this.router.navigate(['signaler']);
    }
  }
  change() {
    this.loaded = false;
    this.router.navigate([this.id, '/pro']);
  }

  co(mail, mdp) {
    if (mail.value == "projetnfctag@gmail.com") {
      this.ngFireAuth.signInWithEmailAndPassword(mail.value, mdp.value).then(res => { this.connected = true; }).catch(err => console.log(err));
    }
  }

  async generateUser(nb) {
    this.fic = "lien	" + "code	" + "\n";
    for (let i = 0; i < nb.value; i++) {
      // this.fic += await this.generateUserDB();
            this.fic += await this.newGenerate();

    }
  }

  async generateCode(nb) {
    for (let i = 0; i < nb.value; i++) {
      let code = await this.generateCodeDB();
    }
  }

  generateNagui() {
    let p = new Profil();

    p.pro.files = new Array<Fichier>();


    p.enable = true;
    p.pro.enabled = true;
    p.social.enabled = true;
    p.findme = true;

    this.firestore.collection('profil').doc("goflito").set({ ...p });
    alert("k");
  }

  removePropertiesExceptOne<T, K extends keyof T>(obj: T, propToKeep: K): RemovePropertiesExceptOne<T, K> {
    const newObj = { [propToKeep]: obj[propToKeep] } as unknown as RemovePropertiesExceptOne<T, K>;
    return newObj;
  }

  /* updateProfil() {
     let p = new Profil();
    // p['pro']['instance'] = Object.assign(p.pro);
    // p['pro']['instance']['pro_files'] = {instance:new ProFile()};
     p.social.instance = {...p.social};
     p.organismePublic.instance = {...p.organismePublic}
     p['organismePublic']['instance']['pro_files'] = {instance:new ProFile()};;
     p.pro.instance = {...p.pro}
     p['pro']['instance']['pro_files'] = {instance:new ProFile()};;
     console.log(p);
     const orga = this.removePropertiesExceptOne(p.organismePublic, "instance");
     const pro = this.removePropertiesExceptOne(p.pro, "instance");
     const social = this.removePropertiesExceptOne(p.social, "instance");
 
     p.organismePublic = orga as any;
     p.pro = pro as any;
     p.social = social as any;
 
     console.log(p); // Output: { name: "John" }
     const flatten = (x: any) => JSON.parse(JSON.stringify(x));
     let proFlat = flatten(p.pro);
     let socFlat = flatten(p.social);
     let orgaFlat = flatten(p.organismePublic);
     p.pro = proFlat;
     p.social = socFlat;
     p.organismePublic = orgaFlat;
     // p.pro = JSON.parse(JSON.stringify(p.pro, (key, value) => {
     //   if (key === 'instance' && (value instanceof ProProfile)) {
     //     return undefined; // Ignorer la propriété 'instance' pour éviter la référence circulaire
     //   }
     //   return value;
     // }));
     console.log(p)
     // p.social = JSON.parse(JSON.stringify(p.social));;
     // p.organismePublic = JSON.parse(JSON.stringify(p.organismePublic));;
     console.log(p); // Output: { name: "John" }
 
     this.firestore.collection('profil').doc("3wCjMu9Wbghec70ghScu").set({ ...p });
     alert("k");
   }*/

  updateProfil() {

    const ids: string[] = [
      "sz6bSa0GpBs6OsfytscT",
      "6Yd2yvHXKCbSh8JxyAon",
      "GPjDyk1NqcmIdKt0OrJJ",
      "NDiBbPG1IxuPs5vOUFUU",
      "C541pReRkpcXVJuMkbi8",
      "myuWpCYlXgzPJeFgvmea",
      "S7cvnn9zbHTkBIH3k3qO",
      "qxqYy2uK1HlQUGwWUrhm",
      "vMIoLMtn2a9z1nXyLPb0",
      "R0Fcl48rj2gcmLvalEME",
      "NLlqu2HCpADEEvHnIFDq",
      "iRC9KZZphGWnNDHqUhzF",
      "ODwhIq0b9Wx7MWTT4IGP",
      "Y4Cu7mAYX2KcYjvi0RUh",
      "9bpXl4J4HPku9bv44IgD",
      "TeZPdWXmclk5Mb4AYIY5",
      "sAMx7F0YE8pyGtnWjSj0",
      "mSVJSymThBVtUuSNq3B8",
      "iVrHEgA01BmMqpfZALhh",
      "OlHgYcsMDJHG2y9WGiIc",
      "S3N0MrkhFOkKq2Px9d34",
      "HJQLl0hMql6WBLyXvmpf",
      "8aaPAh3kzl1Joww33aJF",
      "tTlYd1wNbbc7M0AAVu81",
      "4EWNmtRZ2MmDeWMrCQhv",
      "ilZZUVwg2s1dUHHi9iA2",
      "BJSxOyjmbMVYQD2ln5o3",
      "ewo9GPAZl6XWwyspgbgg",
      "S4nxik3kZtUVVkOLhH1G",
      "VlqPH3MB4ukNwvdk6wHx",
      "RjCzsA9yVvLmCZqsy3UN",
      "TVY1ycugyECv8WX2tYXu",
      "Ql4g06EyGkPqt3pwGGWy",
      "yOt2tDUFrLhPPtHR6VeJ",
      "IM2oPKwYz4Oq5C8yMcv9",
      "gAAjD5dP1WLCpfuzQFg9",
      "V3tTtv1rdoI7gHyDsF6m",
      "M6I3NHf8GSLIgFbZFRd4",
      "Os8CVado7FEfYwph3rBD",
      "tW3mUdKVHrFwzxHhir1M",
      "7s2TlP7QD9c56W3BBqZe",
      "o5vYPGMiKXHIKJsb584U",
      "AQx7O0VeSA5APL8TyUHU",
      "PeBpJpoE1CfedApdfxPB",
      "SAvxM0Bo0wGZ5vBLhF6d",
      "hoxdMUMxoxCKhLQWEzsU",
      "WP6xq7SgazAq06MNhys8",
      "UUKuxSU3gQrfFIUw47KV",
      "8YtbJJe4tbO7gK3nPdJO",
      "zNZaJcq8fHFipKbWOhCK",
      "54qD1OKtWWVLssP7vHyb",
      "Ns1prWygCsZE1mLeB1aH",
      "osTPCAP4c8301z3sNutH",
      "d74jWTieJCK2rkoQ70Xm",
      "0L41etVxwgHQWWB9ND03",
      "1RYDup1x8YkZvklrQ6gR",
      "AK3MuQgj3KhMN35mYYii",
      "42alWyvMujESwyaSsdKy",
      "m36TvS4eZgVBXPg38cwp",
      "4eg8hzTsqD46O0e4FoNW",
      "TZbFjXVEtoirDLe2txBk",
      "a6fQSuL4Fku8BD7xcVge",
      "IzC0dhcPuSTn0qc9ByUj",
      "BWXvYVfnmU6Dc73op00p",
      "p24eOfMSDQw4Nhobm1fw",
      "7WlCqBzWXEZdSWrd3WJd",
      "ijpJograrBoLKhjSoQer",
      "O5bDSJGIubf0k3ElsmMG",
      "ZreYZO0V7hWYaNuVB89D",
      "TsokrwLDP2iRyKFtbBjA",
      "lTItlizBuA4e5TthxwPp",
      "OSMkcAMEejNBMz8lHnYC",
      "aDYBY42GPCphePkokX8V",
      "BKnyb5z9fXZQcxsw2quw",
      "unB8W2PHIuZ0nBIttoj5",
      "szbo9NhMCmao8RSw8lcn",
      "U96K3BU7Ln4UzOHjSSEi",
      "MEewXL5oahpw8bb8t0pM",
      "xL7ZdbevhAhfCipW7t0W",
      "SGJYfaVVhaXxuiWZH6Lw",
      "4MJULGDBwnjJnUKLbaLS",
      "MrJGmIuPLdCWUL86jdug",
      "1HFdlqc9s70gs4ojfNaM",
      "WNiG2qdxBGayhQAoZrRT",
      "nvMUNUwndFxS8ToZ596C",
      "oRLjszXLa7sWKMZhga8Z",
      "3wCjMu9Wbghec70ghScu",
      "M8fNBY3zhgYTa42PEpRe",
      "kBChtodcxTjQ0fK52JxR",
      "swo8WsPuynP5E7ymWM3Y",
      "994rP8xTbSwqAGTdNsGK",
      "L7mC8Kezs2PSxq4tqRUl",
      "ZH2kdkIRLY9em8ml9zLr",
      "19NwY9dY9mzmZH10xuF0",
      "GHTu41xZxR78iK2NLVsR",
      "NouwUvsl5HAe6S0lI0gj",
      "Zms57Jeyv1gMA3NgcoMU",
      "5x5x5xbWlttBc14mtqEs",
      "vf9iOPaW0iOdKaANhKgT",
      "3DGginSQKJ4qIHJdmyWs"
    ];
    
    ids.forEach((id) => {
   
    
    let p = new Profil();
    const flatten = (x: any) => JSON.parse(JSON.stringify(x));
    p.enable = true;
    const pro = p.pro;
    const social = p.social;
    const orga = p.organismePublic;
    p.id =id;

    delete p.pro;
    delete p.social;
    delete p.organismePublic;


    this.firestore.collection('profil').doc(id).set({ ...p });

    this.firestore.collection('profil').doc(id).collection('pro').doc("instance")
      .set(flatten(pro));

      this.firestore.collection('profil').doc(id).collection('pro').doc("instance").collection("pro_files").doc("instance")
      .set(flatten(new ProFile));

      this.firestore.collection('profil').doc(id).collection('social').doc("instance")
      .set(flatten(social));

      this.firestore.collection('profil').doc(id).collection('organismePublic').doc("instance")
      .set(flatten(orga));

      this.firestore.collection('profil').doc(id).collection('organismePublic').doc("instance").collection("pro_files").doc("instance")
      .set(flatten(new ProFile));
    });

  }


  async newGenerate() {
   
    const id = this.firestore.createId();

      let p = new Profil();
      const flatten = (x: any) => JSON.parse(JSON.stringify(x));
      p.enable = true;
      const pro = p.pro;
      const social = p.social;
      const orga = p.organismePublic;
      p.id =id;
  
      delete p.pro;
      delete p.social;
      delete p.organismePublic;
  
  
      this.firestore.collection('profil').doc(id).set({ ...p });
  
      this.firestore.collection('profil').doc(id).collection('pro').doc("instance")
        .set(flatten(pro));
  
        this.firestore.collection('profil').doc(id).collection('pro').doc("instance").collection("pro_files").doc("instance")
        .set(flatten(new ProFile));
  
        this.firestore.collection('profil').doc(id).collection('social').doc("instance")
        .set(flatten(social));
  
        this.firestore.collection('profil').doc(id).collection('organismePublic').doc("instance")
        .set(flatten(orga));
  
        this.firestore.collection('profil').doc(id).collection('organismePublic').doc("instance").collection("pro_files").doc("instance")
        .set(flatten(new ProFile));

        return "https://my-hellotag.fr/" + id + "	" + "\n";

      
  }



  async generateUserDB() {
    let p = new Profil();

    p.enable = true;
    p.pro.enabled = true;
    p.social.enabled = true;
    p.findme = true;


    const id = this.firestore.createId();
    this.firestore.collection('profil').doc(id).set({ ...p });

    let code = this.makeid('5');

    console.log(id);
    console.log(code);

    this.firestore.collection('verification').doc().set({ code: await this.sha256(code) });
    this.coday(code);
    return id + "	" + code + " " + await this.sha256(code) + "\n";
  }

  async generateCodeDB() {
    let code = this.makeid('5');
    this.firestore.collection('verification').doc().set({ code: await this.sha256(code) });
    this.coday(code);
    return code + " " + await this.sha256(code) + "\n";
  }

  coday(code) {
    this.elCodo += "" + code + "," + "flyer.jpg" + "\n";
  }

  async sha256(ascii: string) {
    return buf2hex(await window.crypto.subtle.digest("SHA-256", new TextEncoder().encode(ascii)));
  }

  makeid(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() *
        charactersLength));
    }
    return result;
  }

  downloadCode(type) {
    var file = new Blob([this.elCodo], { type: type });
    const url = window.URL.createObjectURL(file);
    window.open(url);
  }

  downloadCommande(type) {
    var file = new Blob([this.fic], { type: type });
    const url = window.URL.createObjectURL(file);
    window.open(url);
  }

}
