import { Component, OnInit, OnDestroy } from '@angular/core';
import { Profil } from 'src/app/model/profil';
import { ActivatedRoute, Router } from '@angular/router';
import { ProfilService } from 'src/app/services/profil.service';

@Component({
  selector: 'app-disable',
  templateUrl: './disable.component.html',
  styleUrls: ['./disable.component.css']
})
export class DisableComponent implements OnInit,OnDestroy {


  id;
  p: Profil;
  sub;

  constructor(private route: ActivatedRoute, private profilService: ProfilService, private router: Router) { }
  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  ngOnInit(): void {

    this.id = this.route.snapshot.params.id;
    this.sub = this.profilService.getProfilSub(this.id).subscribe(profil => {
      this.p = profil;
      if (this.p.enable) {
        this.router.navigate([`//${this.id}`]);
      }
    });
  }
}

