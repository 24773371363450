import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import firebase from "firebase/app";
import { map, take } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/auth';
import { User } from '../model/user';

@Injectable({
  providedIn: 'root'
})
export class UserService {


  constructor(private firestore: AngularFirestore) {
  }

  async init() {
    //
  }


  async getUser(id: string) {     
    const u = await this.firestore
      .collection<User>('users', ref => ref.where(firebase.firestore.FieldPath.documentId(), '==', id))
      .get()
      .toPromise();
    if (u?.docs[0]?.exists)
      return u.docs[0].data();
    else
      return null;
  }


}
