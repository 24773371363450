import { Injectable } from '@angular/core';
import firebase from "firebase/app";
import { Profil } from '../model/profil';
import { AngularFirestore } from '@angular/fire/firestore';
import { ProfilEntity } from '../model/profilEntity';
import { User } from '../model/user';
import { Social } from '../model/social';
import { Subject, BehaviorSubject, Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Fic } from '../model/fic';



@Injectable({
  providedIn: 'root'
})



export class ProfilService {

  profil: Subject<Profil>;

  constructor(private firestore: AngularFirestore) {
    this.profil = new Subject();
  }

  async convertToApiV2(p: any) {
    //const ss = this.scan;
    const fs = this.firestore;
    const ret: Profil & { v?: number } = new Profil;

    ret.enable ||= p.enable;
    ret.findme ||= p.findme;
    ret.id ||= p.id;
    ret.userId ||= p.userId;


    //const scans = await ss.getScans(p.id).pipe(take(1)).toPromise();
    //ret.scans = scans;
    //const scanned = await ss.scannedBy(p.id).pipe(take(1)).toPromise();
    //ret.scannedby = scanned;

    for (const i in ret.pro)
      if (('pro_' + i) in p)
        (ret.pro as any)[i] = p['pro_' + i];

    for (const i in ret.social)
      if ((i + '_social') in p)
        (ret.social as any)[i] = p[i + '_social'];

    ret.pro.pro_files = { 'files': new Array(), 'passcode': null as any };
    ret.pro.photo ||= p?.pro_photo;
    ret.social.photo ||= p?.social_photo;
    ret.pro.files ||= p?.fichiers;
    ret.pro.enabled ||= p?.pro?.enabled;
    ret.social.enabled ||= p?.social?.enabled;
    ret.v = 2;
    if (ret.userId != this.user?.uid) // upgrade local uniquement, pas les droits d'écrire
      return ret;
    await fs.collection('profil').doc(ret.id).set(ret);
    return ret;
  }

  user?: User;

  async convertToApiV3(ret: Profil & { v?: 2 }) {
    const fs = this.firestore;
    const r = { ...ret, subfields: ['pro', 'social','organismePublic'] as const, v: 3 };
    if (ret.userId != this.user?.uid) // upgrade local uniquement, pas les droits d'écrire
      return r;
    await fs.collection('profil').doc(ret.id).collection('pro').doc('instance').set(ret.pro);
    await fs.collection('profil').doc(ret.id).collection('social').doc('instance').set(ret.social);
    await fs.collection('profil').doc(ret.id).set(r);
    return r;
  }

  async fetchProfil(pid: string) {
    const val = await this.firestore.collection('profil', ref => ref.where(firebase.firestore.FieldPath.documentId(), '==', pid))
      .get().toPromise();
    if (val.empty)
      return null;
    let data: Profil & { v?: number } = val.docs[0].data() as any;
    if (!data.v || data.v < 2)
      data = await this.convertToApiV2(data);
    //if (data.v == 2)
    return await this.convertToApiV3(data as any);
    return data;
  }


  getFic(profil: Profil) {
    return this.firestore.collection('profil').doc(profil.id).collection('fic').snapshotChanges().pipe(map(r =>
      r.map(rel => {
        let data = rel.payload.doc.data() as Fic;
        return data;
      })
    ))
  }

  prev: Subscription;
  prevpid?: string;

  clear() {
    this.prev?.unsubscribe();
    this.prevpid = undefined;
  }

  // async getProfil(pid: string) {
  //   if (this.prevpid != pid) {
  //     this.prev?.unsubscribe();
  //     this.prevpid = pid;
  //     let kk = this.firestore.collection('profil', ref => ref.where(firebase.firestore.FieldPath.documentId(), '==', pid))
  //       .snapshotChanges()
  //       .pipe(switchMap(async r => {
  //         const ret = r.map(async rel => {
  //           let data: Profil & { v?: number };
  //           switch (rel.type) {
  //             case "added":
  //             case "modified":
  //               data = rel.payload.doc.data() as any;
  //               if (!data.v || data.v < 2)
  //                 data = await this.convertToApiV2(data);
  //               //if (data.v == 2)
  //               return await this.convertToApiV3(data as any);
  //           }
  //         });
  //         const rret = await Promise.all(ret);
  //         //this.profil.next(rret[0]);
  //         return rret[0];
  //       }), switchMap(kk => kk));
  //     this.prev = kk.subscribe(val => {
  //       this.profil.next(val);
  //     })
  //   }
  //   return this.profil;
  // }

  async getProfil(pid: string) {
    const val = await this.firestore.collection('profil', ref => ref.where(firebase.firestore.FieldPath.documentId(), '==', pid))
      .get().toPromise();
    if (val.empty)
      return null;
    let data: Profil & { v?: number } = val.docs[0].data() as any;
    if (!data.v || data.v < 2)
      data = await this.convertToApiV2(data);
    if (data.v == 2)
      data = await (await this.convertToApiV3(data as any));
    this.profil.next(data);
    return data;
  }

  // getFullProfil(pid: string) {

  //   return this.firestore.collection('profil', ref => ref.where(firebase.firestore.FieldPath.documentId(), '==', pid))
  //     .snapshotChanges()
  //     .pipe(switchMap(async r => {
  //       const ret = r.map(async rel => {
  //         let data: Profil & { v?: number };
  //         switch (rel.type) {
  //           case "added":
  //           case "modified":
  //             data = rel.payload.doc.data() as any;
  //             if (!data.v || data.v < 2)
  //               data = await this.convertToApiV2(data);
  //             if (data.v == 2)
  //               data = await this.convertToApiV3(data as any) as any;
  //             data.id = rel.payload.doc.id;
  //             for (let sub of data.subfields) {
  //               this.firestore.collection('profil')
  //                 .doc(data.id)
  //                 .collection(sub)
  //                 .doc('instance')
  //                 .snapshotChanges()
  //                 .subscribe(s => {
  //                   if (s.payload.exists) {
  //                     data[sub] = s.payload.data() as any;
  //                   }
  //                 });
  //               data[sub] = (await this.firestore.collection('profil').doc(data.id).collection(sub).doc('instance').get().toPromise()).data() as any;
  //             }
  //             return data;
  //         }
  //       });
  //       const rret = await Promise.all(ret);
  //       return rret[0];
  //     }));
  // }
  getProfilSub(pid: string) {
    return this.firestore.collection('profil', ref => ref.where(firebase.firestore.FieldPath.documentId(), '==', pid))
      .snapshotChanges()
      .pipe(switchMap(async r => {
        const ret = r.map(async rel => {
          let data: Profil & { v?: number };
          switch (rel.type) {
            case "added":
            case "modified":
              data = rel.payload.doc.data() as any;
              if (!data.v || data.v < 2)
                data = await this.convertToApiV2(data);
              if (data.v == 2)
                data = await this.convertToApiV3(data as any);
              data.id = rel.payload.doc.id;
              return data;
          }
        });
        const rret = await Promise.all(ret);
        return rret[0];
      }));
  }

  getFullProfil(pid: string, type: string) {
    return this.firestore.collection('profil', ref => ref.where(firebase.firestore.FieldPath.documentId(), '==', pid))
      .snapshotChanges()
      .pipe(switchMap(async r => {
        const ret = r.map(async rel => {
          let data: Profil & { v?: number };
          switch (rel.type) {
            case "added":
            case "modified":
              data = rel.payload.doc.data() as any;
              if (!data.v || data.v < 2)
                data = await this.convertToApiV2(data);
              if (data.v == 2)
                data = await this.convertToApiV3(data as any);
              data.id = rel.payload.doc.id;
              this.firestore.collection('profil')
                .doc(data.id)
                .collection(type)
                .doc('instance')
                .snapshotChanges()
                .subscribe(s => {
                  if (s.payload.exists) {
                    data[type] = s.payload.data() as any;
                  }
                });
              data[type] = (await this.firestore.collection('profil').doc(data.id).collection(type).doc('instance').get().toPromise()).data() as any;
              return data;
          }
        });
        const rret = await Promise.all(ret);
        this.profil.next(rret[0]);
        return rret[0];
      }));
  }

   getFileProfil(pid: string) {
   return this.firestore.collection('profil')
    .doc(pid)
    .collection('pro')
    .doc('instance')
    .collection('pro_files')
    .doc('instance')
    .get()
    .toPromise();


    //return (await this.firestore.collection('profil').doc(pid).collection('pro').doc('instance').collection('pro_files').doc('instance').get().toPromise());
  }

  delFic(profil: Profil) {
    return this.firestore.collection('profil').doc(profil.id).collection('fic').get().subscribe(x => {
      x.forEach(s => {
        s.ref.delete();
      })
    });
  }

  getMdp(id) {
    return this.firestore.collection('mdpPublic', ref => ref.where("userId", '==', id))
      .snapshotChanges()
      .pipe(map(r =>
        r.map(rel => {
          let data = rel.payload.doc.data();
          return data;
        })
      ))
  }

  getUser(id) {
    return this.firestore.collection('users', ref => ref.where(firebase.firestore.FieldPath.documentId(), '==', id))
      .snapshotChanges()
      .pipe(map(r =>
        r.map(rel => {
          let data = rel.payload.doc.data() as User;
          return data;
        })
      ))
  }

  updateProfil(profil: Profil) {
    return this.firestore.collection('profil').doc(profil.id).update(profil);
  }

  saveProfil(profil: Profil) {

  }

  deleteProfil(id: string) {
    return this.firestore.collection('profil').doc(id).delete();
  }

  deleteMdp(id: string) {
    return this.firestore.collection('mdp', ref => ref.where('userId', '==', id)).get().subscribe(x => {
      x.forEach(s => {
        s.ref.delete();
      })
    });
  }


  deleteMdpProfil(id: string) {
    return this.firestore.collection('mdpProfil', ref => ref.where('userId', '==', id)).get().subscribe(x => {
      x.forEach(s => {
        s.ref.delete();
      })
    });
  }

  deleteUsers(id: string) {
    return this.firestore.collection('users', ref => ref.where('uid', '==', id)).get().subscribe(x => {
      x.forEach(s => {
        s.ref.delete();
      })
    });
  }

  deleteScan(id: string) {
    return this.firestore.collection('scan', ref => ref.where('userId', '==', id)).get().subscribe(x => {
      x.forEach(s => {
        s.ref.delete();
      })
    });
  }

  deleteMail(mail: string) {
    return this.firestore.collection('accounts', ref => ref.where('mail', '==', mail)).get().subscribe(x => {
      x.forEach(s => {
        s.ref.delete();
      })
    });
  }



}
