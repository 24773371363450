import { Component, OnInit } from '@angular/core';
import { ProfilService } from 'src/app/services/profil.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Profil } from 'src/app/model/profil';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { Social } from 'src/app/model/social';
import { take } from 'rxjs/operators';
import { FindmeService } from 'src/app/services/findme.service';
import { Findme } from 'src/app/model/findme';

@Component({
  selector: 'app-findme',
  templateUrl: './findme.component.html',
  styleUrls: ['./findme.component.css']
})
export class FindmeComponent implements OnInit {

  id;
  p: Profil;
  sub;
  findme : Findme;
  isSocial(rs: any): rs is Social {
    return rs && (rs instanceof Social || (typeof rs == "object" && ['name', 'profilId', 'active', 'link', 'findme'].every(k => k in rs)));
  }

  findRes() {
    return Object.values(this.p.pro).filter(e => this.isSocial(e)) as Social[];
  }

  constructor(private sanitizer: DomSanitizer, private http: HttpClient, private profilService: ProfilService, private route: ActivatedRoute, private router: Router, private findmeService:FindmeService) { }


  ngOnDestroy(){
    this.sub.unsubscribe();
  }
  async ngOnInit() {
    this.id = this.route.snapshot.params.id;
    this.sub = this.profilService.getProfilSub(this.id).subscribe(
      profil => {
        this.p = profil;
        if (this.p.enable == false) {
          this.router.navigate([`/desactiver/${this.id}`]);
        }
        if (this.p.findme == false) {
          this.router.navigate([`//${this.id}`]);
        }
      }
    )

    let findme = await this.findmeService.getFindme(this.id);
    this.findme = findme;

  }

  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }
}
