<section class="home-banner dark-image-bg">
    <div class="container text-center">
        <div class="aninimated-title">
            <h2 class="text-uppercase heading-light ls-1 ah-headline">
                <span>Désactiver mon compte</span>

                <!-- <span class="ah-words-wrapper">
                        <b class="is-visible">Creative Designer</b>
                        <b>Passionate Coder</b>
                        <b>Digital Dreamer</b>
                    </span> -->
            </h2>
        </div>
    </div><!-- End Container -->
</section><!-- End Section -->
<!-- ==============================================
    **PROFILE**
    =============================================== -->
<section class="bbs1 bc-light pb-60">
    <div class="container">
        <div class="home-profile">
            <div class="profile-card-two">
                <header class="profile-card-header">
                    <!-- here’s the avatar -->
                    <a href="#">
                        <img src="../../../assets/images/logo2.svg" alt="image">
                    </a>
                    <h2 style="text-align: center">Signalement de compte !</h2>
                    <h4>Tous profils ne respectant pas les moeurs seront supprimés</h4>
                </header>

                <div class="profile-bio" style="margin: auto;display: table;">
<p> Merci d'envoyer un mail à l'adresse : "contact@hellotag.fr" en mentionnant les dérives du profil ainsi que le lien de son profil.</p>
<br>
<p>L'équipe HelloTag !</p>
                </div>


                </div>


            </div>
        </div>
</section>