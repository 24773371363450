// // This file can be replaced during build by using the `fileReplacements` array.
// // `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// // The list of file replacements can be found in `angular.json`.

// export const environment = {
//   production: false,
//   firebase: {
//     apiKey: "AIzaSyAH5UkUWHBz6rAfaV_p1qFzZOlNrbCp9O4",
//     authDomain: "hellotagrec-8a148.firebaseapp.com",
//     projectId: "hellotagrec-8a148",
//     storageBucket: "hellotagrec-8a148.appspot.com",
//     messagingSenderId: "1089806974619",
//     appId: "1:1089806974619:web:fce36b339aa07fa73c903e",
//     measurementId: "G-1W1CLE8JXN",
//     databaseURL: "https://hellotagrec-8a148-default-rtdb.firebaseio.com"
//   }
// };

// /*
//  * For easier debugging in development mode, you can import the following file
//  * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
//  *
//  * This import should be commented out in production mode because it will have a negative impact
//  * on performance if an error is thrown.
//  */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.


export const environment = {
  production: false,
  hmr: true,
  firebase: {
    apiKey: "AIzaSyDUpuu1Uhby_ijhXLey7IusQJS7LPotFEA",
    authDomain: "projetnfc-d9436.firebaseapp.com",
    projectId: "projetnfc-d9436",
    storageBucket: "projetnfc-d9436.appspot.com",
    messagingSenderId: "1089806974619",
    appId: "1:1089806974619:web:fce36b339aa07fa73c903e",
    measurementId: "G-1W1CLE8JXN",
    databaseURL: "https://projetnfc-d9436-default-rtdb.firebaseio.com"
  }
};
