<!-- <section *ngIf="id != 'signaler'" class="home-banner dark-image-bg">
    <div class="container text-center">
        <div class="aninimated-title">
            <h2 class="text-uppercase heading-light ls-1 ah-headline">
                <span *ngIf="id == 'admin'">Administration</span>
                <span *ngIf="id != 'admin'">Pas encore de compte !</span>

          
            </h2>
        </div>
    </div>
</section> -->
<!-- ==============================================
    **PROFILE**
    =============================================== -->
<section *ngIf="id == 'admin'" class="bbs1 bc-light pb-60">
    <div class="container">
        <div class="home-profile">
            <div class="profile-card-two">
                <header class="profile-card-header">
                    <!-- here’s the avatar -->
                    <a href="#">
                        <img src="../../../assets/images/logo2.svg" alt="image">
                    </a>
                    <h2>Admin</h2>
                    <h4>Connexion pannel</h4>
                </header>
                <div class="profile-bio" style="margin: auto;display: table;">
                    <div *ngIf="!connected">
                        <form>
                            <input type="text" placeholder="mail" #mail style="margin-top:10px;margin-bottom: 10px;">
                            <br>
                            <input type="text" placeholder="mdp" #mdp>
                            <br>

                        </form>
                        <button (click)="co(mail,mdp)" style="    background: #3dc2ff;
                        color: white;
                        width: 140px;
                        margin: auto;
                        display: table;
                        margin-top: 20px;" class="btn btn-info">
                            Se connecter
                        </button>
                    </div>
                    <div *ngIf="connected">
                        <form>
                            <input type="text" placeholder="Nombre d'utilisateur" #nb
                                style="display:table; margin:auto;margin-bottom:10px;">
                            <br>
                        </form>

                        <button (click)="updateProfil()"
                            style="margin-top: 20px;width: 140px;height: 30px; border-radius: 6px;margin-left: 5px;background:#3dc2ff; color: white;">
                            Update utilisateur.
                        </button>
                        <button (click)="generateNagui()"
                            style="margin-top: 20px;width: 140px;height: 30px; border-radius: 6px;margin-left: 5px;background:#3dc2ff; color: white;">
                            Générer utilisateur.
                        </button>
                        <button (click)="generateCode(nb)"
                            style="margin-top: 20px;width: 140px;height: 30px; border-radius: 6px;margin-left: 5px;background:#3dc2ff; color: white;">
                            Generer code
                        </button>
                        <button (click)="generateUser(nb)"
                            style="margin-top: 20px;width: 140px;height: 30px; border-radius: 6px;margin-left: 5px;background:#3dc2ff; color: white;">
                            Générer utilisateur.
                        </button>
                        <button (click)="downloadCommande('text/plain')"
                            style="margin-top: 20px;width: 140px;height: 30px; border-radius: 6px;margin-left: 5px;background:#3dc2ff; color: white;">
                            Fichier commande
                        </button>
                        <button (click)="downloadCode('text/plain')"
                            style="margin-top: 20px;width: 140px;height: 30px; border-radius: 6px;margin-left: 5px;background:#3dc2ff; color: white;">
                            Fichier code
                        </button>
                    </div>

                </div>


            </div>
        </div>

    </div><!-- End Container -->
</section>


<div *ngIf=" id !='admin' && id != 'signaler' && !p" class="full-page">
    <div class="top-page">
        <div class="profil">
            <div class="photo">
                <img src="assets/images/logo2.svg" alt="image">
            </div>
            <h2>Pas encore de compte !</h2>
            <h3>Rejoins-nous !</h3>
            <h4 style="padding: 30px">Choppe ton hellotag sur hellotag.fr !</h4>
            <!-- <div class="contact">
                <div class="contact-icon">
                    
                        <img src="assets/images/tel.svg"  />
                    </svg>
                </div>
                <div class="contact-icon">
                    
                        <img src="assets/images/sms.svg"  />
                    </svg>
                </div>
                <div class="contact-icon">
                    
                        <img src="assets/images/adresse.svg"  />
                    </svg>
                </div>
            </div> -->
        </div>

    </div>
    <div class="cwrap">

        <div class="contact">
            <div class="contact-icon">
                <a>

                    <img src="assets/images/mailsms.svg" />
                </a>

            </div>
            <div class="contact-icon">
                <a>
                    <img src="assets/images/tel.svg" />
                </a>
            </div>
            <div class="contact-icon">
                <img src="assets/images/adresse.svg" />

            </div>
            <div class="contact-icon">
                <img src="assets/images/paiement.svg" />

            </div>
        </div>

    </div>
    <div class="dwrap">
        <div class="bottom-page">
            <div>
                <section id="social">
                    <div class="section-title">
                        <h3>Social Media</h3>
                    </div>
                    <div class="section-content-scroll">

                        <div class="card">
                            <div class="card-header"></div>
                            <div class="card-content">
                                <svg width="100%" height="100%" class="my-svg">
                                    <image href="assets/images/youtube.svg" width="100%" height="100%" />
                                </svg>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header"></div>
                            <div class="card-content">
                                <svg width="100%" height="100%" class="my-svg">
                                    <image href="assets/images/instagram.svg" width="100%" height="100%" />
                                </svg>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header"></div>
                            <div class="card-content">
                                <svg width="100%" height="100%" class="my-svg">
                                    <image href="assets/images/vimeo.svg" width="100%" height="100%" />
                                </svg>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header"></div>
                            <div class="card-content">
                                <svg width="100%" height="100%" class="my-svg">
                                    <image href="assets/images/linkedin.svg" width="100%" height="100%" />
                                </svg>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header"></div>
                            <div class="card-content">
                                <svg width="100%" height="100%" class="my-svg">
                                    <image href="assets/images/facebook.svg" width="100%" height="100%" />
                                </svg>
                            </div>
                        </div>
                    </div>

                </section>

                <section id="document">
                    <div class="section-title">
                        <h3>Documents</h3>
                    </div>
                    <div class="section-content">

                        <div class="card-document">

                            <div class="card-document-content">
                                <svg width="100%" height="100%" class="my-svg">
                                    <image href="assets/images/document.svg" width="100%" height="100%" />
                                </svg>
                            </div>
                            <div class="card-document-footer">
                                <p class="doc-text">CV.pdf</p>
                            </div>
                        </div>
                        <div class="card-document">
                            <div class="card-document-content">
                                <svg width="100%" height="100%" class="my-svg">
                                    <image href="assets/images/document-mdp.svg" width="100%" height="100%" />
                                </svg>
                            </div>
                            <div class="card-document-footer">
                                <div class="card-document-footer">
                                    <p class="doc-text">topsecret.pdf</p>
                                </div>
                            </div>
                        </div>
                        <div class="card-document">
                            <div class="card-document-content">
                                <svg width="100%" height="100%" class="my-svg">
                                    <image href="assets/images/video.svg" width="100%" height="100%" />
                                </svg>
                            </div>
                            <div class="card-document-footer">
                                <div class="card-document-footer">
                                    <p class="doc-text">video.mp4</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
        <div class="sticky-bottom">
            <div class="buttons-container">
                <button>Ajouter aux tags</button>
                <button>Ajouter aux contacts</button>
            </div>
        </div>
    </div>

</div><!-- End Section -->



<div *ngIf="id == 'signaler'" class="home-banner dark-image-bg">

    <div *ngIf="id == 'signaler'" class="full-page"
        style="height: 40vh;background: linear-gradient(180deg, #000 0%, #373b3c 99.99%, rgba(76, 165, 251, 0.00) 100%)">
        <div class="top-page">
            <div class="aninimated-title">
                <h2 class="text-uppercase heading-light ls-1 ah-headline">
                    <span style="font-size: 30px;">Signalement de compte</span>

                    <!-- <span class="ah-words-wrapper">
                    <b class="is-visible">Plus de connexions</b>
                    <b>Plus de fluidité</b>
                    <b>HelloTag</b>
                </span> -->
                </h2>
            </div>
        </div><!-- End Container -->
    </div><!-- End Section -->
    <!-- ==============================================
**PROFILE**
=============================================== -->
    <section class="bbs1 bc-light pb-60">
        <div class="container">
            <div class="bottom-page" style="border-radius: 20px;">
                <div class="profile-card-two">
                    <header class="profile-card-header">
                        <!-- here’s the avatar -->
                        <a href="#">
                            <img src="../../../assets/images/logo2.svg" alt="image">
                        </a>
                        <h2>Préservons la communauté !</h2>
                        <h4>En signalant les comptes</h4>
                    </header>

                    <div class="profile-bio" style="margin: auto;display: table;text-align: center;">
                        <p>Tous profils ne respectant pas les moeurs seront supprimés</p>
                        <p> Merci d'envoyer un mail à l'adresse : <a href="contact@hellotag.fr">contact@hellotag.fr</a>
                            en
                            mentionnant les dérives du profil ainsi que le lien de son profil.</p>
                        <br>
                        <p>L'équipe HelloTag !</p>
                    </div>


                </div>
            </div>

        </div><!-- End Container -->

    </section>
</div>