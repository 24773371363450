import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './layout/header/header.component';
import { FooterComponent } from './layout/footer/footer.component';
import { ProComponent } from './pages/pro/pro.component';
import { SocialComponent } from './pages/social/social.component';
import { ProfilService } from './services/profil.service'; 
import { AngularFireModule } from '@angular/fire';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { environment } from 'src/environments/environment';
import { CommonModule } from '@angular/common';
import { HandlerComponent } from './pages/handler/handler.component';
import { FindmeComponent } from './pages/findme/findme.component';
import { DisableComponent } from './pages/disable/disable.component';
import { AdminComponent } from './pages/admin/admin.component';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { DesacComponent } from './pages/desac/desac.component';
import { HttpClientModule } from '@angular/common/http';
import { SignalerComponent } from './pages/signaler/signaler.component';
import { NgxVcardModule } from "ngx-vcard";


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    ProComponent,
    SocialComponent,
    HandlerComponent,
    FindmeComponent,
    DisableComponent,
    AdminComponent,
    DesacComponent,
    SignalerComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireModule.initializeApp(environment.firebase),
    CommonModule,
    HttpClientModule,
    NgxVcardModule
  ],
  providers: [ProfilService],
  bootstrap: [AppComponent]
})
export class AppModule { }
