import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProComponent } from './pages/pro/pro.component';
import { SocialComponent } from './pages/social/social.component';
import { HandlerComponent } from './pages/handler/handler.component';
import { FindmeComponent } from './pages/findme/findme.component';
import { DisableComponent } from './pages/disable/disable.component';
import { AdminComponent } from './pages/admin/admin.component';
import { DesacComponent } from './pages/desac/desac.component';
import { SignalerComponent } from './pages/signaler/signaler.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';


const routes: Routes = [
  {path: 'privacy', component: PrivacyComponent},
  {path: '', children: [
    { path: '', component: HandlerComponent },
    { path: ':id', component: HandlerComponent },
   ]},
   {path: 'pro/:id', component: ProComponent, data: { type: 'pro' }},
   {path: 'pro/:id/:success', component: ProComponent, data: { type: 'pro' }},
   {path: 'social/:id', component: SocialComponent, data: { type: 'social' }},
   {path: 'findme/:id', component: FindmeComponent},
   {path: 'desactiver/:id', component: DisableComponent},
   {path: 'admin', component: AdminComponent},
   {path: 'desac/:id', component: DesacComponent},
   {path: 'signaler', component: SignalerComponent}



];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
