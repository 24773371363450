



<div *ngIf="p" class="full-page">
            <div class="top-page">
                <div class="profil">
                    <div class="photo">
                        <img src="assets/images/logo2.svg" alt="image">
                    </div>
                    <h2>Hellotag perdu :( !</h2>

                    <!-- <div class="contact">
                    <div class="contact-icon">
                        
                            <img src="assets/images/tel.svg"  />
                        </svg>
                    </div>
                    <div class="contact-icon">
                        
                            <img src="assets/images/sms.svg"  />
                        </svg>
                    </div>
                    <div class="contact-icon">
                        
                            <img src="assets/images/adresse.svg"  />
                        </svg>
                    </div>
                </div> -->
                </div>

            </div>
            <div class="cwrap" style="margin-top:20px">

                <div class="profile-bio">
                    <div class="ptb-20 text-center">
                        <p>Hey HelloTagueur! Un autre membre de la communauté semble avoir perdu son HelloTag.</p>
                        <p>Tu trouveras dans la section suivante le moyen de contact de son choix !</p>

                    </div>

                </div>

            </div>
            <div class="dwrap">
                <div class="bottom-page">
                    <div>
                        <section id="social">
                            <div class="section-title">
                                <h3>Mon contact</h3>
                            </div>
                            <div *ngIf="findme" style="width:100%">
                                <a [href]="'https://' + findme.name + '.com/' + findme.link">
                                    <div class="icon-box-one">
                                        <div class="icon-box-icon icon-box-icon-solid icon-box-icon-rounded"
                                            style="background:none">
                                            <img style="width: 100%;display: table;padding:20px;width: 200px;" [src]="'../../../assets/images/'+ findme.name +'.png'" alt="">
                                        </div>
                                    </div>
                                </a>
                            </div>


                        </section>
                    </div>
                </div>
            </div>
        </div>






