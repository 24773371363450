import { Component, OnInit } from '@angular/core';
import { ProfilService } from 'src/app/services/profil.service';
import { Profil } from 'src/app/model/profil';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  toggled = false;
  burgerColor;
  color;
  p: Profil = new Profil();
  type;
  constructor(private profilService: ProfilService, private route: ActivatedRoute,private router: Router,private firestore: AngularFirestore) { }

  async ngOnInit() {
    this.profilService.profil.subscribe( async p => {
      this.p = p;
      this.type = this.route.snapshot.data['type'];
      if((this.p['color'] != null || this.p['color'] != undefined ) && this.p.color != "") {
        let colorQuery = await this.firestore.collection('colors', ref => ref.where("couleur","==",this.p['color'])).get().toPromise();
        this.color = colorQuery.docs[0].data() as any;       
        this.burgerColor = this.color['burger'];
        if(this.route.snapshot.firstChild.url[0].path == 'social') {
          this.burgerColor = 'black';
        }
  
      } else {
        let colorQuery = await this.firestore.collection('colors', ref => ref.where("couleur","==","bleu")).get().toPromise();
        this.color = colorQuery.docs[0].data() as any;      
        this.burgerColor = this.color['burger'];
      }
    })

  }
  goToSocial() {
    this.router.navigateByUrl(`/social/${this.p.id}`);
  }
}
