
import { Social } from './social';
import { Fichier } from './fichier';
import { Fic } from './fic';
import { ProFile } from './proFile';
import { MyScan } from './myscan';

export class BaseProfile {
    passcode: string | null = null;
    titre = '';
    photo: string | null = null;
    banner: string | null = null;
    nom = '';
    prenom = '';
    profession = '';
    bio = '';
    age = 0;
    adresse = '';
    tel = '';
    mail = '';
    active = false;
    enabled = true;
    findme = false;
    type = null;
}

export class NormSocial extends BaseProfile {
    facebook = new Social("facebook");
    instagram = new Social("instagram");
    internet = new Social("internet");
    linkedin = new Social("linkedin");
    paypal = new Social("paypal");
    pinterest = new Social("pinterest");
    snapchat = new Social("snapchat");
    tiktok = new Social("tiktok");
    twitter = new Social("twitter");
    telegram = new Social("telegram");
    telephone = new Social("telephone");
    vimeo = new Social("vimeo");
    whatsapp = new Social("whatsapp");
    youtube = new Social("youtube");
    signal = new Social("signal");
    tripadvisor = new Social("tripadvisor");
    google = new Social("google");
    thefork = new Social("thefork");
    booking = new Social("booking");
    price;
}

export class ProSocial extends NormSocial {
    internet = new Social("internet");
    linkedin = new Social("linkedin");
}

export class ProProfile extends ProSocial {
    pro_files:ProFile;
    files: Fichier[] = [];
    price: string;
    links: Array<Link> = new Array();
}

export class Link {
    name: string = "";
    img: string = "";
    link: string = "";
}


export class Profil {
    v = 5;
    pro = new ProProfile();
    social = new NormSocial();
    organismePublic = new ProProfile();
    scans: MyScan[] | undefined;
    scannedby: MyScan[] | undefined;
    id = '';
    userId = '';
    findme = false;
    enable = true;
    pro_secure = false;
    social_secure = true;
    pro_enabled = true;
    social_enabled = true;
    fic_secure = false;
    color: string;
    type;
    readonly subfields = ['pro', 'social','organismePublic'] as const;
}
