import { Component, OnInit, OnDestroy } from '@angular/core';
import { ProfilService } from 'src/app/services/profil.service';
import { Profil } from 'src/app/model/profil';
import { Social } from 'src/app/model/social';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, Route, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { AngularFireAuth } from '@angular/fire/auth';
import { VCard, VCardEncoding, VCardFormatter } from 'ngx-vcard';
import { AngularFirestore } from '@angular/fire/firestore';
import { BehaviorSubject } from 'rxjs';
import { ProfilesService } from 'src/app/services/pro-files.service';
import { User } from 'src/app/model/user';
import { UserService } from 'src/app/services/user.service';
import { ToolsService } from 'src/app/services/tools.service';
import { SectionService } from 'src/app/services/section.service';
import { SectionContainer } from 'src/app/model/sectionContainer';
import { Section, Size } from 'src/app/model/section';
import { MyScan } from 'src/app/model/myscan';
import { Exchange } from 'src/app/model/exchange';


const buf2hex = (buffer: ArrayBuffer) =>
  [...new Uint8Array(buffer)]
    .map(x => x.toString(16).padStart(2, '0'))
    .join('');

@Component({
  selector: 'app-pro',
  templateUrl: './pro.component.html',
  styleUrls: ['./pro.component.css']
})
export class ProComponent implements OnInit, OnDestroy {

  backgroundStyle: string;
  btnStyle: string;
  errorPay: boolean = false;
  p: Profil = new Profil();
  px: BehaviorSubject<Profil> = new BehaviorSubject(null);
  sms: string;
  tel: string;
  mail: string;
  adresse: string;
  submitted:boolean = false;
  exSuccess=null;

  id;
  success;
  mdp: boolean;
  pw: number;
  message: string;
  verified: boolean = false;
  messageFic = "";
  openFic = false;
  openPw = false;
  ficSecure = false;
  fics;
  loaded = true;
  loaded2 = false;
  sub;
  vCard: VCard;
  creds;
  sub2;
  user;
  height;
  color;
  notifMessage;
  whatsapp;
  sections;

 

  constructor(public ngFireAuth: AngularFireAuth,
    private profilService: ProfilService,
    private sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private store: AngularFirestore,
    private profileService: ProfilesService,
    private userService: UserService,
    private toolsService: ToolsService,
    private firestore: AngularFirestore,
    private sectionService: SectionService) {

  }



  openExchange(){}

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
    this.sub2?.unsubscribe();
  }

  generateUID(): string {
    return 'uid-' + Math.random().toString(36).substr(2, 9) + '-' + Date.now().toString(36);
}

  async submitForm(event,prenom,nom,entreprise,mail,tel,message) {
    event.preventDefault()
    this.submitted = true;
    let id = this.generateUID();
    let ex = new Exchange(id,nom,prenom,tel,entreprise,message,this.p.id,false,new Date(),this.p.userId,mail);
    try {
      let k = await this.firestore.collection('exchange').doc(id).set({ ...ex });
      this.exSuccess= true;
    } 
    catch (e) {
      this.submitted = false;
      this.exSuccess= false;

    }
    this.submitted = false;

  }
 

  isVideoFileType(fileType: string): boolean {
    const videoFileRegex = /^video\/(mp4|mov|avi|wmv)$/;
    return videoFileRegex.test(fileType);
  }

  checkSecurity(p: Profil) {
    if (p.pro_enabled) {
      if (this.p.pro_secure) {
        this.mdp = true;
        //   setTimeout(function () {
        //     document.getElementById("pw").click()
        //   }, 1);
        //   setTimeout(function () {
        //     document.getElementById("pw").focus()
        //   }, 1)
      }
      else {
        this.mdp = false;
      }
    } else {
      this.router.navigate([`../${this.id}`]);
    }

    if (p.findme) {
      this.router.navigate([`findme/${this.id}`]);
    }

  }

  type: 'pro' | 'social';
  async ngOnInit() {
    this.type = this.route.snapshot.data['type'];
    this.id = this.route.snapshot.params.id;
    this.success = this.route.snapshot.params.success;
    let sectionContainer =  await (await this.sectionService.getSections(this.id));
    if(sectionContainer) {
      this.sections = sectionContainer.sections;
    } else {
      let secCont = {} as SectionContainer;
      secCont.profilId = this.id;

      let sec1 = {} as Section;
      let sec2 = {} as Section;
      let sec3 = {} as Section;
      let sec4 = {} as Section;

      
      sec1.taille = Size.M;
      sec2.taille = Size.M;
      sec3.taille = Size.M;
      sec4.taille = Size.M;

      sec1.nom = "Social Media";
      sec2.nom = "Liens utiles";
      sec3.nom = "Documents";
      sec4.nom = "Paiements";


      secCont.sections = [sec1,sec2,sec3,sec4]
      secCont.userId = this.p.userId;

      await this.sectionService.setSection(this.id,secCont);
    }

    if (this.success == 'success') {
      this.http.get(`https://us-central1-projetnfc-d9436.cloudfunctions.net/resetPrice?id=${this.id}`).subscribe();
    }
    if (this.user == null) {
      this.creds = this.user;
      await this.genericAuth();
    }
    this.user = await this.userService.getUser(this.creds.user.uid);
    let p = await this.getProfil();
    this.http.get(`https://europe-west2-projetnfc-d9436.cloudfunctions.net/saveViews-1?id=${this.id}`).subscribe(e => {console.log(e)},er => {console.log(er)});
    if (!this.p.fic_secure) {
      //this.profileService.getFile2(this.p.userId).subscribe(f => {this.fics = f; this.openFic = true});
    }
    console.log(this.verified + " " + this.mdp);
    // this.loaded = false;
    if ((this.p['color'] != null || this.p['color'] != undefined) && this.p.color != "") {
      let colorQuery = await this.firestore.collection('colors', ref => ref.where("couleur", "==", this.p['color'])).get().toPromise();
      this.color = colorQuery.docs[0].data() as any;
      this.backgroundStyle = this.color['background'];
      this.btnStyle = this.color['button'];
    } else {
      let colorQuery = await this.firestore.collection('colors', ref => ref.where("couleur", "==", "bleu")).get().toPromise();
      this.color = colorQuery.docs[0].data() as any;
      this.backgroundStyle = this.color['background'];
      this.btnStyle = this.color['button'];
    }

  }


  downloadAndEncodeImage() {

    this.http.get(this.p.pro.photo, { responseType: 'arraybuffer' }).subscribe(
        (data: ArrayBuffer) => {
            const binary = new Uint8Array(data);
            const base64 = this.arrayBufferToBase64(binary);
            return base64; // Si le type MIME n'est pas défini
        },
        error => {
            console.error('Error downloading image:', error);
        }
    );
}


arrayBufferToBase64(buffer: Uint8Array) {
  let binary = '';
  for (let i = 0; i < buffer.length; i++) {
    binary += String.fromCharCode(buffer[i]);
  }
  return btoa(binary);
}

  downloadVcard() {
    // Contenu du fichier vCard
    const vCardData = `
BEGIN:VCARD
VERSION:3.0
FN:${this.p.pro.nom}
ORG:${this.p.pro.titre}
TEL:${this.p.pro.tel}
EMAIL:${this.p.pro.mail}
PHOTO;VALUE=BASE64:${this.downloadAndEncodeImage()}
ADR:${this.p.pro.adresse}
END:VCARD
`;

    // Créer un objet Blob avec le contenu vCard
    const blob = new Blob([vCardData], { type: 'text/vcard' });

    // Créer un objet URL à partir du Blob
    const blobUrl = window.URL.createObjectURL(blob);

    // Créer un élément d'ancre pour déclencher le téléchargement
    const a = document.createElement('a');
    a.href = blobUrl;
    a.download = 'fiche_contact.vcf';

    // Ajouter l'élément ancre au corps du document
    document.body.appendChild(a);

    // Simuler un clic sur le lien pour déclencher le téléchargement
    a.click();

    // Retirer l'élément ancre du corps du document
    document.body.removeChild(a);

    // Libérer l'URL de l'objet Blob
    window.URL.revokeObjectURL(blobUrl);
  }


  getWidthBySize(taille: string): number {
    switch (taille) {
      case 'S':
        return 30;
      case 'M':
        return 60;
      case 'L':
        return 120;
      default:
        return 60; // Valeur par défaut ou gestion d'erreur si nécessaire
    }
  }

  getWidthBySizeD(taille: string): number {
    switch (taille) {
      case 'S':
        return 70;
      case 'M':
        return 89;
      case 'L':
        return 140;
      default:
        return 89; // Valeur par défaut ou gestion d'erreur si nécessaire
    }
  }

  async checkout() {
    // const options = { withCredentials: true };
    // let x = await ('https://us-central1-projetnfc-d9436.cloudfunctions.net/testistripi').toPromise();
    this.loaded = true;
    this.http.get(`https://us-central1-projetnfc-d9436.cloudfunctions.net/testistripi?id=${this.p.id}`).subscribe((session) => {
      this.loaded = false;
      this.errorPay = false;

      // Redirige l'utilisateur vers la page de paiement Stripe
      window.location.href = session['session']['url']
    },
      (error) => {
        this.loaded = false;
        this.errorPay = true;
        console.error('Erreur lors de la récupération de la session de paiement:', error);
        // Gérez l'erreur selon les besoins de votre application
      }
    );
    //  window.location.href = session['url'];
    //  this.http.get(`https://europe-west1-projetnfc-d9436.cloudfunctions.net/getFic?id=${this.p.id}`).subscribe();
  }

  async genericAuth() {
    this.creds = await this.ngFireAuth.signInAnonymously();

    let u = await this.userService.getUser(this.creds.user.uid);



    if (!!u) {
      // let x = this.toolsService.mergeDeep(u);
      // x.uid = this.creds.user.uid;
      // x.method = "anonymous";
      await this.store.collection('users').doc(this.creds.user.uid).update(
        { uid: this.creds.user.uid, method: "anonymous" }
      );
    } else {
      // let x = this.toolsService.mergeDeep(u);
      // x.uid = this.creds.user.uid;
      // x.method = "anonymous";
      await this.store.collection('users').doc(this.creds.user.uid).set(
        { uid: this.creds.user.uid, method: "anonymous" }
      );
    }

    // this.creds = await this.ngFireAuth.signInWithEmailAndPassword("testzoom1337@gmail.com", "GenericUser1337");
    return this.creds;
  }


  notif(type: string) {
    this.http.get(`https://europe-west1-projetnfc-d9436.cloudfunctions.net/notif?id=${this.p.id}&notif=${type}`).subscribe(() => {
      this.notifMessage = "Votre notification est arrivée !";
      setTimeout(() => {
        this.notifMessage = null;
      }, 500); // 30 secondes (30000 millisecondes)
    });

  }


  async getProfil() {
    let profil = await this.profilService.getProfil(this.id);
    this.p = profil;
    if (!this.p.pro_enabled) {
      this.router.navigate([`//${this.id}`]);
    }
    console.log(this.creds);
    console.log(this.p);
    if (this.p.pro_secure) {
      this.mdp = true;
      this.loaded = false;
      // setTimeout(function () {
      //   document.getElementById("pw").click()
      // }, 1);
      // setTimeout(function () {
      //   document.getElementById("pw").focus()
      // }, 1);
    } else {
      this.mdp = false;

      this.sub = this.profilService.getFullProfil(this.id, this.type).subscribe(
        async profil => {
          this.p = profil;
          this.height = this.p.pro.adresse.length > 35 ? "15vh" : "100%";
          this.checkSecurity(this.p);
          this.vCard = {
            name: {
              firstNames: this.p[this.type].nom,
              lastNames: this.p[this.type].prenom,
            },
            email: [this.p[this.type].mail],
            telephone: [this.p[this.type].tel],
            // photo: {
            //   value: this.p.pro.photo,s
            //   param: {
            //     value: "uri"
            //   }
            // }
          }
          if (this.p.pro.banner) {
            this.backgroundStyle = `linear-gradient(0deg, ${this.color['burger']} 0%, rgb(7 7 7 / 80%) 100%), url(${this.p.pro.banner}), lightgray 50% / cover no-repeat`
          }
          else {
            this.backgroundStyle = this.color['background'];

          }
          if (this.p.pro.enabled == false || this.p.enable == false) {
            this.router.navigate([`//${this.id}`]);
          }
          this.tel = `tel:${this.p[this.type].tel}`;
          this.sms = `sms:${this.p[this.type].tel}`;
          this.mail = `mailto:${this.p[this.type].mail}`;
          this.adresse = `https://www.google.com/maps/search/${this.p[this.type].adresse}`;
          this.whatsapp = `https://wa.me/${this.p.pro.whatsapp.link}`

          if (!this.p.fic_secure)
            this.p.pro.pro_files = (await this.profilService.getFileProfil(this.id)).data() as any;


          if (this.type == "pro") {
            if (this.p.fic_secure) {
              this.openFic = false;
              // this.openPw = true;
            } else {
              this.openFic = true;
              // this.openPw = false;
              //this.fics = this.p.pro.files;
              //this.http.get(`https://europe-west1-projetnfc-d9436.cloudfunctions.net/getFic?id=${this.p.id}`).subscribe(f => { this.fics = f; this.loaded2 = false; });
            }
          }
          this.loaded = false;
        }, err => {

          this.router.navigate([`../${this.id}`]);
          this.loaded = false;

        }
      )
    }


    if (this.p.enable == false) {
      this.loaded = false;
      this.router.navigate([`/desactiver/${this.id}`]);
    }


    //this.profilService.profil.next(this.p);


  }

  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  openApp() {
    // window.location.href = `hellotag://${this.id}&${this.p[this.type].nom}`;
    window.location.href = `https://my-hellotag.web.app/${this.id}&pro`;

  }

  async sha256(ascii: string) {
    return buf2hex(await window.crypto.subtle.digest("SHA-256", new TextEncoder().encode(ascii)));
  }


  openFile(file: number, mdp: string) {
    this.messageFic = "";
    let haveMdp = false;

    this.profilService.getMdp(this.p.id).subscribe(async x => {
      if (x[0]['fic'] != null && x[0]['fic'] != ' ') {
        haveMdp = true;
      }
      if (haveMdp) {
        if (mdp != null) {
          if (await this.sha256(mdp) == x[0]['fic']) {
            this.openHref(file);
          } else {
            this.messageFic = "Mot de passe incorrect";
          }
        }
      }
    });
  }

  openHref(idx: number) {
    location.href = this.p.pro.files[idx].link;
  }

  openMdp(file, mdp) {
    this.openFile(file, mdp);
  }



  async verif(mdp: HTMLInputElement) {
    this.loaded = true;
    let creds = await this.genericAuth();
    try {
      let x = this.toolsService.mergeDeep(this.user, { [this.id]: { pro: mdp.value } });
      await this.store.collection('users').doc(creds.user.uid).update(
        { ...x }
      );
      this.profilService.clear();
      // this.profilService.getProfil(this.id);
      await new Promise(r => setTimeout(r, 1000));
      this.loaded = false;
      this.message = "";
      await new Promise(r => setTimeout(r, 1000));

      this.sub2 = this.profilService.getFullProfil(this.id, this.type).subscribe(async profil => {
        this.p = profil;
        this.height = this.p.pro.adresse.length > 35 ? "15vh" : "100%";
        this.checkSecurity(this.p);
        this.vCard = {
          name: {
            firstNames: this.p[this.type].nom,
            lastNames: this.p[this.type].prenom,
          },
          email: [this.p[this.type].mail],
          telephone: [this.p[this.type].tel],
          // photo: {
          //   value: this.p.pro.photo,s
          //   param: {
          //     value: "uri"
          //   }
          // }
        }
        if (this.p.pro.enabled == false || this.p.enable == false) {
          this.router.navigate([`//${this.id}`]);
        }
        this.sms = `sms:${this.p[this.type].tel}`;
        this.mail = `mailto:${this.p[this.type].mail}`;
        this.whatsapp = `https://wa.me/${this.p.pro.whatsapp.link}`
        // debugger
        //this.p.pro.pro_files = (await this.profilService.getFileProfil(this.id)).data() as any;
        console.log(this.p.pro.pro_files)
        if (this.mdp)
          this.verified = this.p[this.type].passcode == mdp.value;
        if (!this.verified) {
          this.mdp = true;
          this.message = "Mot de passe incorrect !"
        }
      }, err => {
        this.mdp = true;
        this.message = "Mot de passe incorrect !";
      })



    } catch (e) {
      this.mdp = true;
      this.message = "Une erreur est survenue, veuillez contacter HelloTag"
    }
  }

  isSocial(rs: any): rs is Social {
    let prout = rs && (rs instanceof Social || (typeof rs == "object" && ['name', 'profilId', 'active', 'link', 'findme'].every(k => k in rs)));
    if (prout && typeof rs.link !== 'undefined') {
      // Vérifie si la valeur de 'link' contient la chaîne "undefined"
      if (rs.link.includes("undefined")) {
        // Si 'link' contient la chaîne "undefined", supprimez-la de la valeur de 'link'
        rs.link = rs.link.replace("undefined", "");
      }
    }

    return prout;
  }

  findRes(): Social[] {
    return Object.values(this.p.pro)
      .filter(e => this.isSocial(e))
      .sort((a, b) => (a.rank || 0) - (b.rank || 0)) as Social[];
  }

  async verifFic(mdp) {
    let x = this.toolsService.mergeDeep(this.user, { [this.id]: { fic: mdp.value } });
    this.store.collection('users').doc(this.user.uid).update(
      { ...x }
    ).then(async () => {

      try {

        this.p.pro.pro_files = await (await this.profilService.getFileProfil(this.id)).data() as any;
        this.openFic = true;

      } catch {
        this.messageFic = "Mot de passe incorrect"
      }

    }, err => {
      this.messageFic = "Mot de passe incorrect"
    })
    // this.profileService.getFileByUserId(this.p.userId).subscribe(fics => {
    //   this.fics = fics;
    //   this.openFic = true;
    // })
    // this.profileService.getFile2(this.p.id).subscribe(f => this.fics = f);

  }

}
