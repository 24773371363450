import { Component } from '@angular/core';
// Firebase App (the core Firebase SDK) is always required and must be listed first
import firebase from "firebase/app";
// If you are using v7 or any earlier version of the JS SDK, you should import firebase using namespace import
// import * as firebase from "firebase/app"

// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import "firebase/analytics";

// Add the Firebase products that you want to use
import "firebase/auth";
import "firebase/firestore";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'projetNfc';
  constructor() {
/*    const firebaseConfig = {
      apiKey: "AIzaSyDUpuu1Uhby_ijhXLey7IusQJS7LPotFEA",
      authDomain: "projetnfc-d9436.firebaseapp.com",
      projectId: "projetnfc-d9436",
      storageBucket: "projetnfc-d9436.appspot.com",
      messagingSenderId: "1089806974619",
      appId: "1:1089806974619:web:fce36b339aa07fa73c903e",
      measurementId: "G-1W1CLE8JXN",
      databaseURL: "https://projetnfc-d9436-default-rtdb.firebaseio.com"
    };
    firebase.initializeApp(firebaseConfig);*/
  }
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional

}
