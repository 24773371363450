export class Exchange {
    id: string;
    nom: string;
    prenom: string;
    tel: string;
    entreprise: string;
    message: string;
    profilId: string;
    edit: boolean;
    date: Date;
    userId: string;
    mail:string;

    constructor(
        id: string,
        nom: string,
        prenom: string,
        tel: string,
        entreprise: string,
        message: string,
        profilId: string,
        edit: boolean = false,
        date: Date = new Date(),
        userId: string,
        mail: string
    ) {
        this.id = id;
        this.nom = nom;
        this.prenom = prenom;
        this.tel = tel;
        this.entreprise = entreprise;
        this.message = message;
        this.profilId = profilId;
        this.edit = edit;
        this.date = date;
        this.userId = userId;
        this.mail = mail;
    }
}
