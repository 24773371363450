import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import firebase from "firebase/app";
import { map, take } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/auth';
import { User } from '../model/user';
import { SectionContainer } from '../model/sectionContainer';

@Injectable({
    providedIn: 'root'
})
export class SectionService {


    constructor(private firestore: AngularFirestore) {
    }

    async init() {
        //
    }


    async getSections(lien: string) {
        const u = await this.firestore
            .collection<SectionContainer>('sections', ref => ref.where("profilId", '==', lien))
            .get()
            .toPromise();
        if (u?.docs[0]?.exists)
            return u.docs[0].data();
        else
            return null;
    }

    async setSection(lien: string, sectionContainer: SectionContainer) {

        const u = await this.firestore
            .collection<SectionContainer>('sections')
            .doc()
            .set(sectionContainer)
    }


}
